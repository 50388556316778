/**
 *
 * This component generates and renders all tokens
 * 
 */
import React from "react"
// eslint-disable-next-line
// eslint-disable-next-line
import { Row, Col, Box } from "adminlte-2-react"
import { getUserTokens } from "../../services/users"
import { getUser } from "../../services/auth"
import TokenCard from './token-cards'
import { Button } from 'adminlte-2-react'
import {  getTokenIcon } from "../../services/token"

// import PropTypes from "prop-types"
// import { PayloadCards } from "./payload-cards"

let _this
export class MyTokens extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      tokens: []
    }
    _this = this
  }
  render() {
    const { loaded, tokens } = _this.state

    return (
      <div >
      <Button className='btn btn-primary mt-1 mb-1' icon='fa-redo' text={<span className='ml-1'>Refresh</span>} onClick={_this.refresh} />

        {!loaded && <Box  className="border-none loading-box text-center" loaded={loaded} ><h3>User Tokens</h3></Box>
        }
        {loaded && tokens.length > 0 && (
          <>
            <Row >
              {tokens.map((val, i) => {
                if (val.qty > 0) {
                  return (
                    <Col sm={4} key={`token-${i}`}>
                      <TokenCard
                        key={`token-${i}`}
                        id={`token-${i}`}
                        token={val}
                        hideBurnBtn={true}
                        {..._this.props}
                      />
                    </Col>
                  )
                } else {
                  return <span key={`token-${i}`} />
                }
              })}
            </Row>
          </>
        )}
        {(loaded && !tokens.length) && <Box className={"text-center"}>Tokens Not Found!</Box>}
        {/** hide button for refresh tokens */}
        <button className='display-none' id='refresh-mytokens' onClick={_this.refresh}>refresh</button>
      </div>

    )
  }
  async componentDidMount() {
    await _this.getTokens()
  }
  async getTokens() {
    // Get Addresses
    try {

      _this.handleLoaded(false)
      const user = getUser()
      const result = await getUserTokens(user._id)
      if (!result.success) {
        _this.setState({
          errMsg: 'Tokens cant be found!'
        })
        _this.handleLoaded(true)

        return
      }

      const tokens = await _this.getTokenIcons(result.tokens)

      _this.setState({
        tokens: tokens,
        loaded: true
      })

      //const userD = _this.props.userData
      // userD.addresses = result.addresses
      // _this.props.setUserData(userD)
    } catch (error) {
      console.warn(error)
      _this.handleLoaded(true)

    }
  }
  async refresh(){
    await _this.getTokens()

  }
  handleLoaded(value) {
    _this.setState({
      loaded: value,
    })
  }

  async getTokenIcons(tokens) {
    try {
      const _tokens = []
      for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i]
        let icon
        try {
          icon = await getTokenIcon(token.tokenId)
        } catch (error) {
          icon = ''
        }
        token.icon = icon
        _tokens.push(token)

      }
      return _tokens
    } catch (error) {
      console.log(error)
    }
  }
}