/* eslint-disable */
/**
 * This modal renders the form
 * to share payloads
 */
import React from "react"
import PropTypes from "prop-types"
import { Button, Box, Content, Inputs } from "adminlte-2-react"
import { NotificationManager } from "react-notifications"
import { tokenizePayload } from "../../../services/token"
const { Text } = Inputs

let _this
const TokenDisplayUrl = 'https://token.fullstack.cash/'

export class TokenizePayload extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      txid: "",
      inFetch: false,
      customData: 'default',
      tokenizeName: '',
      tokenizeTicker: '',
    }
  }

  render() {
    const { txid, customData } = _this.state
    return (
      <Content
        className=""
        modal
        modalCloseButton={true}
        onHide={_this.props.onHide}
        show={true}
        modalSize="lg"
        /* Modal Header */
        title={<React.Fragment><div className="tx-time-content">
          <p>Tokenize Payload.</p>
        </div></React.Fragment>}
        modalFooter={
          <React.Fragment>
            <Box className="tx-footer-box" loaded={!_this.state.inFetch}>
              <div className="tx-footer">
                <Button
                  type="primary"
                  text={txid ? 'Close' : 'Cancel'}
                  onClick={_this.props.changeSection}
                />
                {!txid && <Button
                  type="primary"
                  text="Tokenize"
                  onClick={_this.tokenize}
                />}
              </div>

            </Box>
          </React.Fragment>
        }
      >
        {!txid && <div className='text-center'>
          <Box
            className="disclosure-form-box-loader"
          >
            <p className='tokenize-select-label'>Token Data.</p>
            <select
              className='tokenize-select'
              id="customData"
              name="customData"
              onChange={_this.handleUpdate}
            >
              <option value={'default'}> Default Data </option>
              <option value={'custom'}> Custom Data</option>

            </select>
            {
              customData === 'custom' && <div className='text-center'>
                <Text
                  id="tokenizeName"
                  name="tokenizeName"
                  placeholder="Enter Token Name"
                  label="Name"
                  labelPosition="above"
                  onChange={_this.handleUpdate}
                />
                <Text
                  id="tokenizeTicker"
                  name="tokenizeTicker"
                  placeholder="Enter Token Ticker"
                  label="Ticker"
                  labelPosition="above"
                  onChange={_this.handleUpdate}
                />
              </div>
            }
          </Box>
          <p>Are you sure you want to tokenize the payload ?</p>

        </div>}
        {txid && <div className='text-center'>
          <p>The payload has been tokenized! This transaction ID will take you to a block explorer where you can see the data uploaded to the blockchain</p>
          <p onClick={this.openExplorer}>TXID :  <u className="txid-link">{txid}</u></p>
        </div>}
      </Content>
    )
  }
  componentDidMount() {
  }
  fillDefault() {
    try {
      const { selectedPayload } = _this.props

      const nameElem = document.getElementById('tokenizeName')
      nameElem.value = selectedPayload.title

      const tickerElem = document.getElementById('tokenizeTicker')
      tickerElem.value = selectedPayload.title
    } catch (error) {
    }
  }
  handleUpdate(event) {
    const name = event.target.name
    let value = event.target.value
    if (name === 'customData' && value) {
      setTimeout(() => {
        _this.fillDefault()
      }, 300);
    }
    _this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  async tokenize() {
    try {
      _this.setState({
        inFetch: true,
      })
      const { tokenizeName, tokenizeTicker, customData } = _this.state
      const { selectedPayload } = _this.props
      //// tokenize request
      let customObj

      if (customData === 'custom') {
        customObj = {
          name: tokenizeName,
          ticker: tokenizeTicker
        }
      }
      const result = await tokenizePayload(selectedPayload._id, customObj)
      // console.log("result", result)
      if (!result || !result.txid) {
        throw new Error("Error")
      }
      _this.setState({
        txid: result.txid,
        inFetch: false,
      })
      NotificationManager.success("Tokenize Payload success!", "Success", 2000)
    } catch (error) {
      console.warn(error.message)
      NotificationManager.error(error.message, "Tokenize Payload Error")
      _this.setState({
        inFetch: false,
      })
    }
  }
  openExplorer() {
    const url = `${TokenDisplayUrl}?tokenid=${_this.state.txid}`
    window.open(
      url,
      "_blank"
    )
  }
}



TokenizePayload.propTypes = {
  selectedPayload: PropTypes.object,
  onHide: PropTypes.func,
  changeSection: PropTypes.func
}
