import React from "react"


export class EmbedCard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}
	render() {
    const { embedId }= this.props
		return (
			<div className="video-responsive mt-2">
				<iframe
					width="853"
					height="480"
					src={`https://www.youtube.com/embed/${embedId}`}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title="Embedded youtube"
				/>
			</div>
		)
	}
	componentDidMount() {
	}
}