import "isomorphic-fetch"
import { getUser } from "./auth"
const axios = require('axios').default

const SERVER = process.env.GATSBY_API_URL

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined"

// Get all payloads
export const getProjects = async () => {
  const token = getUser().jwt ? getUser().jwt : ""

  //Try to get all payloads
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/projects`, options)
    console.log(resp)
    if (resp.ok) {
      return resp.json()
    } else {
      throw new Error(resp.statusText)
    }
  } catch (e) {
    throw e
  }
}

// Create new payload
export const newProject = async project => {
  //Try to create new payload
  const token = getUser().jwt ? getUser().jwt : ""
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        project: project,
      }),
    }
    const resp = await fetch(`${SERVER}/projects`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// Get all nda payloads by project id
export const getNdas = async project => {
  const token = getUser().jwt ? getUser().jwt : ""

  //Try to get  payload by id
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(
      `${SERVER}/projects/ndas/${project._id}`,
      options
    )
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const exportProjectToken = async ( tokenId, slpAddress, projectId) => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/export/project`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        tokenId,
        slpAddress,
        projectId
      }
    }
    const data = await axios(options)
    const txids = data.data //await data.json()
    return txids.result[0]
  } catch (e) {
    return false
  }
}

export const burnProjectToken = async (projectId ,tokenId) => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/burn/project`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        projectId,
        tokenId,
      }
    }
    const data = await axios(options)
    const txids = data.data //await data.json()
    console.log('txids',txids)
    return txids.result[0]
  } catch (e) {
    throw e
  }
}
