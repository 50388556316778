/* eslint-disable */
/**
 * This modal renders the form
 * to share payloads
 */
import React from "react"
import PropTypes from "prop-types"
import { getNdas } from "../../../services/projects"
import { Button, Inputs, Box } from "adminlte-2-react"
const { Text, Radio, Checkbox } = Inputs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

let _this

export class Share extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      email: "",
      nda: "",
      message: "",
      emailList: [],
      formMessage: "",
      ndaPayloads: [],
      fetchingNda: true,
      ndaSelected: "",
      emailCheck: true,
    }
  }

  render() {
    return (
      <>
        <div>
          <p className="label-email">SEND TO EMAIL ADDRESS</p>
          <div className="emails-container">
            {_this.state.emailList.map((val, i) => {
              return (
                <div key={i} className="email-box">
                  <span>{val}</span>
                  <FontAwesomeIcon
                    className="close-icon"
                    icon="times"
                    pull="left"
                    onClick={() => this.emailDelete(i)}
                  />
                </div>
              )
            })}
          </div>

          <div className="shareImput">
            <div className="email-input">
              <Text
                id="email"
                name="email"
                placeholder="Enter Email"
                label=""
                labelPosition="above"
                onChange={this.handleUpdate}
                onKeyDown={this.handleKeyDown}
                onBlur={this.handleUpdate}
                onFocus={this.handleUpdate}
              />
            </div>
            <div className="email-button">
              <Button
                type="primary"
                text="Add"
                label="SEND TO EMAIL ADDRESS"
                labelPosition="none"
                onClick={this.addEmail}
              />
            </div>
          </div>
          <b>EMAIL CHECK</b>
          <Box className="checkbox-container">
            <Checkbox
              value={_this.state.emailCheck} // mark as checked
              text={_this.state.emailCheck ? "Enabled" : "Disabled"}
              labelPosition="none"
              labelXs={0}
              name="emailCheck"
              onChange={this.handleUpdate}
            />
          </Box>
          <p className="share-message">{_this.state.message}</p>

          <b>SHARE THIS SECRET UNDER A CONFIDENTIALITY AGREEMENT</b>
          <div className="checkbox-container">
            <form name="radioInputs" className="radioInputs">
              <input
                type="radio"
                id="no-nda-radio"
                name="nda"
                value="no-nda"
                onChange={this.handleUpdate}
                defaultChecked
              />
              <span>Agreement at log-in applies.</span>
              <br></br>
              <input
                type="radio"
                id="nda-radio"
                name="nda"
                value="nda"
                onChange={this.handleUpdate}
                onClick={_this.getNDA}
              />
              <span>
                All recipients of the shared secret are bound by the attached
                confidentiality agreement.
              </span>
            </form>
            {_this.state.nda === "nda" ? (
              <>
                <div className="warning-nda">
                  <FontAwesomeIcon
                    className="close-icon"
                    icon="exclamation-triangle"
                    pull="left"
                  />
                  <span>All users will receive the same sharing agreement</span>
                </div>
                {
                  <Box
                    className="disclosure-form-box-loader"
                    loaded={!_this.state.fetchingNda}
                  >
                    <select
                      id="nda-select"
                      name="ndaSelected"
                      onChange={_this.handleUpdate}
                    >
                      {_this.state.ndaPayloads.length ? (
                        <option value="">
                          - Please Select a sharing agreement -
                        </option>
                      ) : (
                        <option value="">- No NDAs found -</option>
                      )}

                      {_this.state.ndaPayloads.map(val => {
                        return (
                          <option key={val._id} value={val._id}>
                            {val.title}
                          </option>
                        )
                      })}
                    </select>
                  </Box>
                }
              </>
            ) : (
              ""
            )}
          </div>

          <Text
            id="formMessage"
            name="formMessage"
            inputType="textarea"
            label="MESSAGE (REQUIRED)"
            labelPosition="above"
            rows={5}
            onChange={this.handleUpdate}
          />
        </div>
      </>
    )
  }
  componentDidMount() {}
  handleUpdate(event) {
    const name = event.target.name
    let value = event.target.value

    // Turn on/off email address validator
    if (name === "emailCheck") {
      value = !_this.state.emailCheck
      const isEmail = value ? _this.validateEmail(_this.state.email) : true

      if (!isEmail) {
        _this.setMessage("This email is invalid!")
      } else {
        _this.setMessage("")
      }
      _this.props.handleEmailCheck(value)
    }

    if (name === "email") {
      const isEmail = _this.state.emailCheck ? _this.validateEmail(value) : true
      if (!isEmail && value !== "") {
        _this.setMessage("This email is invalid!")
      } else {
        _this.setMessage("")
      }
      // set email to parent component
      _this.props.setEmailFormData(value)
    }
    if (name === "formMessage") {
      _this.props.setMessage(value)
    }

    _this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      _this.addEmail()
    }
  }
  addEmail() {
    const isEmail = _this.state.emailCheck
      ? _this.validateEmail(_this.state.email)
      : true
    if (!isEmail) {
      return
    }
    if (_this.validateEmailRepeat()) {
      _this.setMessage("This Email already exist!")
      return
    }
    const list = _this.state.emailList

    list.push(_this.state.email)
    _this.setState({
      emailList: list,
      email: "",
    })

    const inputElement = document.getElementById("email")
    inputElement.value = ""

    _this.props.setEmailList(list)
  }
  emailDelete(i) {
    const list = _this.state.emailList
    list.splice(i, 1)

    _this.setState({
      emailList: list,
    })
  }
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }
  validateEmailRepeat() {
    return _this.state.emailList.includes(_this.state.email)
  }
  setMessage(value) {
    _this.setState({
      message: value,
    })
  }
  // Get Payloads NDA
  async getNDA() {
    try {
      if (!_this.state.fetchingNda) return
      const { selectedPayload, payloads } = _this.props
      const result = await getNdas({ _id: selectedPayload.projectId })
      const ndasIDs = result.payloads

      const payloadsArr = []

      // Find the Payload models for each NDA
      ndasIDs.map(id => {
        const ndaResult = payloads.find(val => id._id === val._id)
        payloadsArr.push(ndaResult)
      })

      const ndaPayloads = _this.sortByTimeStamp(payloadsArr)

      _this.setState({
        ndaPayloads,
        fetchingNda: false,
      })
    } catch (error) {
      console.error(error)
    }
  }
  // Sorts from the most recent to the oldest
  sortByTimeStamp(arr) {
    try {
      const sorted = arr.sort(function(a, b) {
        const timeStampA = a.metadata.createdTimestamp
        const timeStampB = b.metadata.createdTimestamp

        const dateA = new Date(timeStampA * 1000)
        const dateB = new Date(timeStampB * 1000)
        const diff = dateB - dateA

        return diff
      })
      return sorted
    } catch (error) {
      console.error(error)
    }
  }
}

Share.propTypes = {
  setEmailList: PropTypes.func,
  setMessage: PropTypes.func,
  setEmailFormData: PropTypes.func,
  payloads: PropTypes.array,
  handleEmailCheck: PropTypes.func,
}
