import "isomorphic-fetch"
import { getUser } from "./auth"

const SERVER = process.env.GATSBY_API_URL

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined"

// Get all metadatas
export const getPayloads = async () => {
  const token = getUser().jwt ? getUser().jwt : ""

  //Try to get all metadatas
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/metadatas`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

// Get  metadatas by id
export const getPayloadById = async payload => {
  const token = getUser().jwt ? getUser().jwt : ""

  //Try to get  metadatas by id
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/metadatas/${payload._id}`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const getPayloadByIdBULK = async payloadIds => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        metadataIds: payloadIds,
      }),
    }
    const resp = await fetch(`${SERVER}/metadatas/byid`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// Create new metadata

export const newPayload = async (payload, fileName) => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        metadata: payload,
        encryptionFileName: fileName,
      }),
    }
    const resp = await fetch(`${SERVER}/metadatas/`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// Update metadata
export const updatePayload = async payload => {
  console.log(payload)
  // Try to update a  metadatas
  const token = getUser().jwt ? getUser().jwt : ""
  try {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        metadata: payload,
      }),
    }
    const resp = await fetch(`${SERVER}/metadatas/${payload._id}`, options)
    const myResp = await resp.json()

    return myResp
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// Delete a metadatas
export const deletePayload = async payload => {
  console.log(payload)
  // Try to delete a metadatas
  const token = getUser().jwt ? getUser().jwt : ""
  try {
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/metadatas/${payload._id}`, options)
    const myResp = await resp.json()

    return myResp
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// Get  payload by id
export const getPayloadByProject = async project => {
  const token = getUser().jwt ? getUser().jwt : ""

  //Try to get  payload by id
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(
      `${SERVER}/metadatas/byproject/${project._id}`,
      options
    )
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
export const downloadFiles = async payload => {
  const token = getUser().jwt ? getUser().jwt : ""

  // Try to get  payload by id
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(
      `${SERVER}/metadatas/download/${payload._id}`,
      options
    )
    // console.log(resp)
    if (resp.ok) {
      return resp
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

// Get  payload by id
export const checkFunds = async () => {
  // Try to get  payload by id
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
    const resp = await fetch(`${SERVER}/metadatas/check/`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const showFiles = async payloadId => {
  const token = getUser().jwt ? getUser().jwt : ""

  // Try to get  payload by id
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(
      `${SERVER}/metadatas/showFiles/${payloadId}`,
      options
    )
    console.log("resp", resp)

    // console.log('resp',resp)
    if (resp.ok) {
      return resp
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const newMacroPayload = async (metadata, encryptionFileName ,ignoreEncryption) => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        metadata,
        encryptionFileName,
        ignoreEncryption
      }),
    }
    const resp = await fetch(`${SERVER}/macro/metadata/`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

export const getMacroProof = async payloadId => {
  const token = getUser().jwt ? getUser().jwt : ""

  // Try to get  payload by id
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/macro/proof/${payloadId}`, options)
    // console.log('resp',resp)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
