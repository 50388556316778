/**
 *
 * This component generates and renders all the
 * payload cards shared to the user
 */
import React from "react"
// eslint-disable-next-line
// eslint-disable-next-line
import { Row, Col, Box } from "adminlte-2-react"
import PropTypes from "prop-types"
import { PayloadCards } from "./payload-cards"
let _this
export class SharedWithMe extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      disclosures: [],
    }
  }
  render() {
    return (
      <>
        {_this.props.sharedWithMe.length ? (
          <PayloadCards
            {..._this.props}
            hideAddCard={true}
            payloadList={_this.props.sharedWithMe}
          />
        ) : (
          <Box padding="true" className="container-nofound">
            <Row>
              <Col xs={12}>
                <em>Shared Documents Not Found </em>
              </Col>
            </Row>
          </Box>
        )}
      </>
    )
  }
}
SharedWithMe.propTypes = {
  getPayloadInfo: PropTypes.func,
  showArchived: PropTypes.bool,
}
