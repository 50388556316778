/* eslint-disable */
/**
 * Component that contains the menu functionality
 * and the view in general.
 * This component contains all the views, menu and modals
 *
 *
 *
 */
import React from "react"
import PropTypes from "prop-types"


import { Views } from "./views/index"
import { NewProject } from "../components/projects/modals/newproject"
import { DepositAddress } from "../components/projects/modals/get-deposit-addr"
import { Redeem } from "../components/projects/modals/redeem"
import { Burn } from "../components/projects/modals/burn"

import { Export } from "../components/projects/modals/export"
import { TokenInfo } from "../components/projects/modals/token-info"

import { logout, getUser, getExpirationDate } from "../services/auth"
import { navigate } from "gatsby"
import { NotificationManager } from "react-notifications"
import { getProjects } from "../services/projects"
import AdminLTE, { Sidebar, Navbar } from "adminlte-2-react"
const { Entry } = Navbar
const { Item, Header } = Sidebar
import CustomItem from './custom-lte/item'
import ItemCheckBox from './custom-lte/itemCheckBox'

import Logo from "../images/logo.svg"
import Layout from "./layout"
import NavbarUser from "./user/update"
import { BrowserRouter as Router } from "react-router-dom"
import { getPayloadByProject } from "../services/payload"
import { getPayloadIcon } from "../services/metadatas"

import { ChangeIconModal } from "../components/projects/modals/changeIcon"
let _this
let initialPath

class Dashboard extends React.Component {
	constructor(props) {
		super(props)
		_this = this
		this.state = {
			projects: [],
			currentMenuItem: "Projects",
			showArchivedProjects: false,
			queryPayloadId: "",
			queryFound: false,
			lastSelectedProjectId: null,
			isProjectItemOpen: false,
			isReady: false

		}
	}

	render() {
		const { projects, selectedProject, changeViewTo } = _this.props
		return (
			<>
				<AdminLTE
					browserTitle={["LaunchpadIP.net"]}
					theme="blue"
					title={
						<img
							className="header-logo"
							height="55"
							width="210"
							src={Logo}
							className="App-logo"
							alt="logo"
						/>
					}
					titleShort={""}
				>
					<Navbar.Core>
						<Entry icon="fas-user" labelValue="0" headerText="My Account">
							<NavbarUser />
						</Entry>
						<Entry
							icon="fas-power-off"
							onClick={event => {
								event.preventDefault()
								logout(() => navigate(`/login`))
							}}
						/>
					</Navbar.Core>
					<Sidebar.Core>
						<Header text="MAIN NAVIGATION" />
						{/* User tokens menu item */}
						<CustomItem
							key="tokens"
							icon="fa-coins"
							text="User Tokens"
							to='/tokens'
							onClick={() => changeViewTo('my tokens')}
						/>
						{/* Downloads menu item */}
						<CustomItem
							key="downloads"
							icon="fa-download"
							text="Downloads"
							to='/downloads'
							onClick={() => changeViewTo('downloads')}

						/>
						{/* New Project menu item */}

						<CustomItem
							icon="fa-plus"
							text={'New Project'}
							to="/project/new"
							activeOn="/project/new"
							onClick={() => _this.openNewProjectModal()}
						/>
						{/* Show archived project menu item */}
						<ItemCheckBox
							text='Show Archived'
							label='Show Archived Checkbox'
							id='showArchivedProjects'
							onClick={_this.showArchivedProjects}
						/>
						{/* Projects menu item */}
						{projects.length ? <CustomItem
							key="projects"
							icon="fa-project-diagram"
							text="Projects"
							id='projects'
							onClick={(state) => { _this.onProjectsItemClick(state) }}

						>
							{/*Project  sub-menu items */}
							{projects.map((val, i) => {
								return (
									<CustomItem
										id={`project-${val._id}`}
										key={val._id}
										icon="fa-file"
										text={val.projectLabel}
										activeOn={[`/project/${i + 1}/payloads`, `/project/${i + 1}/tokens`]}
									>
										{/* Payload sub-menu*/}
										<CustomItem
											icon="fa-coins"
											text={'Payloads'}
											activeOn={`/projects/${i + 1}/payloads`}
											to={`/project/${i + 1}/payloads`}
											id={`payload-${val._id}`}
											onClick={() => _this.selectProject(val, 'projects')}

										/>
										{/* Tokens sub-menu*/}
										<CustomItem
											icon="fa-coins"
											text={'Tokens'}
											activeOn={`/projects/${i + 1}/tokens`}
											to={`/project/${i + 1}/tokens`}
											id={`token-${val._id}`}
											onClick={() => _this.selectProject(val, 'project tokens')}
										/>
									</CustomItem>

								)
							})}

						</CustomItem> :
							<CustomItem
								key="empty-projects"
								icon="fa-project-diagram"
								text="Projects"
								id='empty-projects'
								to='/'

							/>
						}


						{/* Shared menu item */}
						<CustomItem
							id='Shared With Me'
							key="shared"
							icon="fa-project-diagram"
							text="Shared With Me"
							to="/shared/"
							onClick={() => changeViewTo('shared with me')}
						/>
						<CustomItem
							key="help"
							icon="fa-question"
							text="Help"
							to='/help'
							onClick={() => changeViewTo('help')}
						/>
						{/* Copyright */}
						<li className="dashboard-patent-text">
							<p>Patent Pending</p>
							<p>© Copyright 2020 - {new Date().getFullYear()} All rights reserved.</p>
						</li>
					</Sidebar.Core>

					{/* Views */}
					{projects.length && selectedProject && (
						<Views
							{..._this.props}
							project={selectedProject}
							navigateToProject={_this.navigateToPyaload}
							getProjects={_this.getProjects}
							getPayloads={_this.getPayloads}
							// path={i === 0 ? `/project/` : `/project/${i + 1}`}
							path="/project/*"
							exact
						/>
					)}
					{!selectedProject && (
						<Layout path="/projects/">
							<section className="loby"></section>
						</Layout>
					)}
					{
						<Views
							{..._this.props}
							project={{}}
							queryPayloadId={
								_this.state.queryFound ? "" : _this.props.queryPayloadId
							}
							onQueryFound={_this.handleQueryFound}
							// path={i === 0 ? `/project/` : `/project/${i + 1}`}
							path="/shared/"
							exact
						/>
					}
					{
						<Views
							{..._this.props}
							// path={i === 0 ? `/project/` : `/project/${i + 1}`}
							path="/tokens/"
							exact
						/>
					}
					{
						<Views
							{..._this.props}
							// path={i === 0 ? `/project/` : `/project/${i + 1}`}
							path="/downloads/"
							exact
						/>
					}
										{
						<Views
							{..._this.props}
							// path={i === 0 ? `/project/` : `/project/${i + 1}`}
							path="/help/"
							exact
						/>
					}
				</AdminLTE>
				{_this.props.currentModal === "new project" && (
					<Router>
						<NewProject
							{..._this.props}
							getProjects={_this.getProjects}
							handleProject={_this.hideNewProjectModal}
							navigateToProject={_this.navigateToProjectItem}
							triggerNotification={_this.triggerNotification}
							path="/"
						/>
					</Router>
				)}
				{_this.props.currentModal === "deposit address" && (
					<Router>
						<DepositAddress
							{..._this.props}
							path="/"
						/>
					</Router>
				)}
				{_this.props.currentModal === "redeem token" && (
					<Router>
						<Redeem
							{..._this.props}
							path="/"
						/>
					</Router>
				)}
				{_this.props.currentModal === "export token" && (
					<Router>
						<Export
							{..._this.props}
							path="/"
						/>
					</Router>
				)}
				{_this.props.currentModal === "token info" && (
					<Router>
						<TokenInfo
							{..._this.props}
							path="/"
						/>
					</Router>
				)}
				{_this.props.currentModal === "burn token" && (
					<Router>
						<Burn
							{..._this.props}
							path="/"
						/>
					</Router>
				)}

				{_this.props.currentModal === "change payload icon" && (
					<Router>
						<ChangeIconModal
							{..._this.props}
							path="/"
						/>
					</Router>
				)}


			</>
		)
	}
	async componentDidMount() {
		const { location } = _this.props
		initialPath = location ? location.pathname : null
		_this.props.setDefaultState()
		_this.verifyQueryParams()
		// Redirects to login if there's not a logged user
		const user = getUser()
		if (!user._id || !user.email || !user.jwt) logout(() => navigate(`/login`))

		const hourDiff = await _this.verifyTokenExpiration()
		// Redirects to login page if the token expires in less than an hour
		if (hourDiff < 1) {
			logout(() => navigate(`/login`))
			return
		}

		const projects = await _this.getProjects()
		if (projects && projects.length) {
			_this.setState({
				lastSelectedProjectId: projects[0]._id
			})
		}
		// Select first project payloads by default
		if (location && location.pathname === '/project/') {
			_this.navigateToProjectItem()
		}
		// Select first project payloads by default
		if (location && location.pathname === '/') {
			_this.navigateToProjectItem()
		}

	}
	// Automated click to navigate to the project item menu
	navigateToProjectItem(index) {
		try {
			const { projects } = _this.state
			const pIndex = index || 1

			const project = projects[pIndex - 1]
			if (!project) return
			_this.setState({
				lastSelectedProjectId: project._id
			})

			const elemen = document.getElementById('projects')
			if (!elemen) throw new Error('elemen not found')
			const tagA = elemen.childNodes[0]

			tagA.click()
		} catch (error) {
			console.warn(error.message)
		}
	}

	// Automated click to navigate to the project item sub-menu
	goToLastSelectedProject(ignoreProjectClick) {
		try {
			const { lastSelectedProjectId, projects } = _this.state

			if (!projects.length) return
			_this.props.hideCurrentModal()

			if (!ignoreProjectClick) {
				const elemen2 = document.getElementById(`project-${lastSelectedProjectId}`)
				const tagA2 = elemen2.childNodes[0]
				tagA2.click()
			}


			const elemen3 = document.getElementById(`payload-${lastSelectedProjectId}`)
			const tagA3 = elemen3.childNodes[0]
			tagA3.click()
			_this.props.changeViewTo('projects')

		} catch (error) {
			console.warn(error)
		}
	}

	// Function to handle the Projects item
	onProjectsItemClick() {
		const { projects } = _this.state


		_this.goToLastSelectedProject()

	}
	// Fetch project payloads
	async getPayloads(project) {
		try {
			if (project && !project._id) return
			const payloads = await getPayloadByProject(project)
			const metadatas = payloads.metadatas
			const _metadatas = []
			for (let i = 0; i < metadatas.length; i++) {
				const metadata = metadatas[i]
				if (metadata.meta.filePreview) {
					const url = await _this.payloadICons(metadata._id)
					metadata.meta.thumbnailIpfsUrl = url
				}
				_metadatas.push(metadata)

			}
			console.log("Payloads : ", _metadatas)
			_this.props.setPayloads(_metadatas)
		} catch (error) {
			console.error(error)
		}
	}
	async payloadICons(payloadId) {
		try {
			if (!payloadId) return
			const result = await getPayloadIcon(payloadId)
			return result
		} catch (error) {
			console.error(error)
		}
	}
	// Fetch user projects
	async getProjects() {
		// Fetch to get all projects
		try {
			const projectsResult = await getProjects()
			if (!projectsResult) throw new Error("Error trying to fetch the projects")
			const userData = getUser() // Get my user from storage
			const myProjects = [] // Users projects array
			// Get only user associated projects
			if (!userData || !userData.projects) return
			projectsResult.projects.map(val => {
				userData.projects.map(value => {
					if (value === val._id) myProjects.push(val)
				})
			})
			console.log('myProjects', myProjects)
			// Update state projects
			_this.setState({
				projects: myProjects,
			})
			_this.props.setProjects(myProjects)
			return myProjects
		} catch (error) {
			// Redirects to login if there's a fetch error
			logout(() => navigate(`/login`))
		}
	}

	// I used the following function as an example to calculate the difference between two dates
	// https://github.com/Dos-Centavos/pearson-api/blob/a53473d1f498731be15d8aee096975f5e50f7d36/src/lib/sweep.js#L38-L55
	async verifyTokenExpiration() {
		const result = await getExpirationDate()

		// If the expiration data are not found returns -1
		// indicating that should redirect to login
		if (!result || !result.now || !result.exp) {
			return -1
		}

		// Dates in ISO format, example --> '2020-10-22T23:27:11.000Z'
		// Testing purposes
		// const now = new Date('2020-10-23T21:27:11.000')
		// const exp = new Date('2020-10-23T22:26:11.000Z')

		const now = new Date(result.now)
		const exp = new Date(result.exp)

		const dateDiff = exp.getTime() - now.getTime()
		const hourDiff = Math.floor(dateDiff / 1000 / 60 / 60)
		//console.log('hourDiff',hourDiff)

		return hourDiff
	}

	// verify if the url has the search parameters
	verifyQueryParams() {
		const { queryPayloadId } = _this.props
		// console.log('queryPayloadId', queryPayloadId)
		if (queryPayloadId) {
			_this.setState({
				queryPayloadId,
			})
			setTimeout(() => {
				try {
					// searchs the shared with me menu on the dom and
					// simulates a click to redirect
					const elem = document.getElementById("Shared With Me")
					const tagA = elem.childNodes[0]
					tagA.click()
					// crear interval
				} catch (error) {
					_this.verifyQueryParams()
				}
			}, 700)
		}
	}
	// Update the local and redux states while selecting a project
	async selectProject(project, view) {
		_this.props.setSelectedProject(project)
		_this.props.changeViewTo(view)

		_this.setState({
			lastSelectedProjectId: project._id
		})
		// fetch project payloads
		await _this.getPayloads(project)
		// Log project info
		console.log(
			`%c Project Name: ${project.projectLabel} , Project ID :  ${project._id}`,
			"background: #222; color: #bada55"
		)

	}

	showArchivedProjects() {
		try {
			_this.setState({
				showArchivedProjects: !_this.state.showArchivedProjects,
			})
		} catch (error) { }
	}

	handleQueryFound() {
		_this.setState({
			queryFound: true,
		})
	}

	openNewProjectModal() {
		_this.props.showModal("new project")
	}

	hideNewProjectModal(goToLastProject) {

		_this.props.hideCurrentModal()
		if (goToLastProject) {
			_this.goToLastSelectedProject()
		}
	}
	triggerNotification(msg, type) {
		NotificationManager.success(msg, type)
	}

	navigateToPyaload(tokenId) {
		try {

			const { payloads, projects } = _this.props
			let lastSelectedProjectId
			let count = 0
			for (let i = 0; i < projects.length; i++) {
				const project = projects[i]
				for (let j = 0; j < project.tokens.length; j++) {
					if (tokenId === project.tokens[j]) {
						lastSelectedProjectId = project._id

					}
				}
			}
			_this.props.setPayloads([])
			//_this.navigateToProjectItem(projectIndex , true)
			_this.setState({
				lastSelectedProjectId
			})

			setTimeout(() => {
				_this.goToLastSelectedProject(true)
				_this.openPayload(tokenId, count)

			}, 500);
		} catch (error) {
			//console.log(error)
		}
	}

	openPayload(tokenId, count) {
		try {
			const { payloads } = _this.props
			count++
			if (count > 15) return
			if (!payloads || !payloads.length) {
				setTimeout(() => {
					_this.openPayload(tokenId, count)
				}, 1000);
			} else {
				for (let i = 0; i < payloads.length; i++) {
					const payload = payloads[i]
					const tokenizedIds = payload.tokenizedIds

					const exist = tokenizedIds.find(val => { return val === tokenId })
					if (exist) {
						_this.props.setSelectedPayload(payload)
						_this.props.showModal("payload details")
						return
					}
				}
			}
		} catch (error) {
			//
		}
	}

}

Dashboard.propTypes = {
	queryPayloadId: PropTypes.string, // Query param value for shared payloads
	payloads: PropTypes.array.isRequired, // Payloads array
	setPayloads: PropTypes.func.isRequired, // Function to update the 'payloads' property of the redux state
	projects: PropTypes.array.isRequired, // Projects array
	setProjects: PropTypes.func.isRequired, // Function to update the 'projects' property of the redux state
	selectedProject: PropTypes.object, // Property that contains the selected project
	setSelectedProject: PropTypes.func.isRequired, // Function to update the 'selectedProject' property of the redux state
	selectedPayload: PropTypes.object, // Property that contains the selected payload
	setSelectedPayload: PropTypes.func.isRequired, // Function to update the 'selectedPayload' property of the redux state
	sharedWithMe: PropTypes.array.isRequired, // Payloads array of the 'shared with me' view
	setSharedWithMe: PropTypes.func.isRequired, // Function to update the 'sharedWithMe' array of the redux state
	changeViewTo: PropTypes.func.isRequired, // Function to change views
	currentView: PropTypes.string.isRequired, // View where is the user currently
	showModal: PropTypes.func.isRequired, // Function to show a modal
	hideCurrentModal: PropTypes.func.isRequired, // Function to hide the modal that is currently open
	currentModal: PropTypes.string.isRequired, // Modal that is currently open
	setDefaultState: PropTypes.func.isRequired, // Function to reset redux state
	setUserData: PropTypes.func.isRequired, // Function to update user data
	userData: PropTypes.object,// user data,
	selectedToken: PropTypes.object, // current selected token
	setSelectedToken: PropTypes.func
}
export default Dashboard
