/* eslint-disable */
/**
 * Chat Modal
 */
import React from "react"
import { Button, Inputs, Content } from "adminlte-2-react"
const { Text, Radio, Checkbox } = Inputs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

let _this

export class Chat extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      logs: "",
      newMessage: "",
    }
  }

  render() {
    const { currentModal, hideCurrentModal } = _this.props
    return (
      <>
        <Content
          className=""
          modal
          modalCloseButton={true}
          onHide={_this.closeChat}
          show={currentModal === "chat"}
          modalSize="lg"
          /* Modal Header */
          title={
            <React.Fragment>
              <FontAwesomeIcon
                className="btnbackIcon"
                size="xs"
                icon="arrow-circle-left"
                onClick={_this.closeChat}
              />
            </React.Fragment>
          }
          /*Modal Footer*/
          /*     modalFooter={
  
            } */
        >
          {/*Modal Body*/}
          <Text
            inputType="textarea"
            label="Chat"
            labelPosition="above"
            rows={15}
            onChange={() => {
              /*Prevent console Error*/
            }}
            value={_this.state.logs}
            readOnly
          />
          <Text
            id="newMessage"
            name="newMessage"
            placeholder="Enter a message"
            label="TITLE"
            labelPosition="none"
            onChange={_this.handleUpdate}
            onKeyDown={_this.handleKeyDown}
            value={_this.state.newMessage}
            buttonRight={
              <Button
                className="tx-time-ico"
                text={"SUBMIT"}
                onClick={_this.submitMsg}
                type="primary"
              />
            }
          />
        </Content>
      </>
    )
  }
  componentDidMount() {}
  closeChat() {
    _this.props.showModal("shared payload details")
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      _this.submitMsg()
    }
  }
  handleUpdate(event) {
    const name = event.target.name

    let value = event.target.value

    _this.setState({
      [name]: value,
    })
  }
  submitMsg() {
    const { newMessage, logs } = _this.state

    _this.setState({
      logs: logs + "\n" + newMessage,
      newMessage: "",
    })
  }
}
