/* eslint-disable */
/**
 * This component is in charge of rendering a modal
 * with the export token functionality
 */
import React from "react"


import { Content, Box, Inputs, Button, Row, Col } from "adminlte-2-react"
import QScanner from '../../qr-scanner'
import { exportUserToken } from '../../../services/users'
import { exportProjectToken } from '../../../services/projects'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getUserAddrs } from "../../../services/users"
import { getUser } from "../../../services/auth"

const { Text } = Inputs

// const QRCode = require('qrcode.react')

let _this
export class Export extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      address: '',
      section: 'send', // send , explorer , scanner , export-options
      txid: '',
      errMsg: '',
      showWarning: false,
      isLoaded: true,
      successMsg: ''
    }
    // Message for the tokens exported to other wallets
    _this.successMsg1 = 'The token has been exported from this application to the address you gave. The transaction ID below is proof of the transaction. The link will take you to a block explorer showing the details of the transaction.'
    // Message for the tokens exported to the user account
    _this.successMsg2 = 'The token has been exported from this section to the user tokens wallet. The transaction ID below is proof of the transaction. The link will take you to a block explorer showing the details of the transaction.'
  }


  render() {
    const { currentModal, currentView, selectedToken } = _this.props
    const { section, txid, errMsg, isLoaded, address, showWarning, successMsg } = _this.state

    return (
      <>
        <Content
          className="disclosure-form-container"
          title={_this.modalHeader()}
          modal
          // eslint-disable-next-line
          modalCloseButton={true}
          modalCloseButton={true}
          onHide={_this.hideModal}
          /* modalFooter={this.modalFooter} */
          show={currentModal === "export token"}
        >
          <Box loaded={isLoaded} className="border-none box-body">
            {
              section === 'send' && <div className='text-center'>
                <Text
                  id='addressToSend'
                  name='address'
                  placeholder='Enter address to send'
                  label='Address'
                  labelPosition='above'
                  onChange={_this.handleUpdate}
                  className='title-icon'
                  value={address}
                  buttonRight={
                    <Button icon='fa-qrcode' className='text-main-color' onClick={() => _this.changeSection('scanner')} />
                  }
                />
                {showWarning && <Box className='text-center'><p className='warn-txt-msg'>
                  <p><b>WARNING</b></p>
                  <p><b>Careful! Not all Bitcoin Cash wallets are token-aware. If you send this token to a wallet that is not token-aware, it could be burned. It's best practice to only send tokens to 'simpleledger' addresses.</b></p>
                </p> </Box>}
                <div>
                  <Button
                    text='Send'
                    type='primary'
                    className='btn-lg btn-send-token mt-1'
                    onClick={_this.handleSend}
                  />
                </div>
              </div>
            }

            {section === 'scanner' && <div className='text-center'>
              <QScanner
                onError={_this.setError}
                onScan={_this.handleScan}
              />
            </div>
            }

            {section === 'explorer' && txid && <div className='text-center'>
              <p>{successMsg}</p>
              <span>TXID: <a href={`https://token.fullstack.cash/transactions/?txid=${txid}`} target="_blank">{txid}</a></span>
            </div>
            }
            {
              section === 'export-options' && <div className='text-center'>
                <p className='subtitle-text'>Export token to: </p>

                <Row>
                  <Col xs={5}>
                    <div className="icon-container" onClick={() => _this.handleExportOption(false)}>
                      <FontAwesomeIcon
                        className="export-option-icon"
                        icon="user"                 
                      />
                    </div>
                    <p className='option-text'>User Tokens Wallet</p>

                  </Col>
                  <Col xs={2} />
                  <Col xs={5}>
                    <div className="icon-container" onClick={() => _this.handleExportOption(true)}>
                      <FontAwesomeIcon
                        className="export-option-icon"
                        icon="share-square"
                      />
                    </div>
                    <p className='option-text'>External Wallet</p>

                  </Col>

                </Row>

              </div>
            }
            {errMsg && <div className='text-center'><p className='err-txt-msg'>{errMsg}</p> </div>}



          </Box >

        </Content>

      </>
    )
  }

  async componentDidMount() {
    setTimeout(() => {
      _this.refreshTokens()
    }, 3000);

    if (_this.props.currentView === 'project tokens') {
      _this.setState({
        section: 'export-options'
      })
    }

    // fetch user addresses if it does not exist in redux store

  }

  modalHeader() {
  
    const { section , isLoaded} = _this.state
    const { selectedToken, currentView } = _this.props
    if(!isLoaded) return `Export ${selectedToken.ticker}`
    // Change the header of the modal depending of the view and the sections
    // 'project tokens' view
    if (currentView === 'project tokens') {
      if (section === 'scanner') {
        // Return button
        return <div className='export-token-header'><FontAwesomeIcon  className='text-main-color' icon='arrow-circle-left' onClick={() => _this.changeSection('send')} /></div>
      } else
        if (section === 'send') {
           // Return button
          return <div className='export-token-header'> <FontAwesomeIcon className="text-main-color" icon="arrow-circle-left" onClick={() => _this.changeSection('export-options')}   /> {`Export ${selectedToken.ticker}`}</div>
        }
        else {
          return `Export ${selectedToken.ticker}`
        }
    } else { // 'my tokens' view
      if (section === 'scanner') {
         // Return button 
        return <div className='export-token-header'><FontAwesomeIcon  className='text-main-color' icon='arrow-circle-left' onClick={() => _this.changeSection('send')} /></div>
      } else {
        return `Export ${selectedToken.ticker}`
      }
    }


  }
  handleUpdate(event) {
    const value = event.target.value
    const isBchAddr = _this.isBchAddr(value)

    _this.setState({
      [event.target.name]: value,
      showWarning: isBchAddr ? true : false,
      errMsg: false
    })
    // console.log(_this.state)
  }

  async handleSend(_inputAddr) {
    try {
      const inputAddr = typeof _inputAddr === 'string' ? _inputAddr : null
      const address = inputAddr || _this.state.address
      const { selectedToken, currentView } = _this.props
      console.log('address', address)

      if (!address) {
        throw new Error('Slp Address is required')
      }
      const isSlpAddr = _this.isSlpAddr(address)
      const isBchAddr = _this.isBchAddr(address)

      if (!isSlpAddr && !isBchAddr) {
        _this.setState({
          errMsg: 'Input must be simpleledger or bicoincash address'
        })
        return
      }

      _this.setState({
        isLoaded: false,
        errMsg: '',
        showWarning: false,
        successMsg: inputAddr ? _this.successMsg2 : _this.successMsg1
      })


      // Request for user tokens
      if (currentView === "my tokens") {
        await _this.exportUserToken(address, selectedToken)
      }
      // Request for project tokens
      if (currentView === "project tokens") {
        await _this.exportProjectToken(address, selectedToken)
      }

    } catch (error) {
      _this.setState({
        isLoaded: true
      })
      _this.setError(error.message)
    }
  }

  async exportUserToken(addr, token) {
    try {

      const result = await exportUserToken(token.tokenId, addr)
      _this.setState({
        txid: result,
        isLoaded: true
      })
      _this.changeSection('explorer')

    } catch (error) {
      _this.setState({
        isLoaded: true
      })
      _this.setError(error.message)
    }
  }

  async exportProjectToken(addr, token) {
    try {
      const { selectedProject } = _this.props
      const result = await exportProjectToken(token.tokenId, addr, selectedProject._id)
      _this.setState({
        txid: result,
        isLoaded: true
      })
      _this.changeSection('explorer')

    } catch (error) {
      _this.setState({
        isLoaded: true
      })
      _this.setError(error.message)
    }
  }


  hideModal() {
    _this.refreshTokens()
    _this.props.hideCurrentModal()
  }

  handleScan(data) {
    const validateAdrrs = ['simpleledger', 'bitcoincash']
    try {
      _this.resetAddressValue()
      if (!data) {
        throw new Error('No Result!')
      }
      if (typeof data !== 'string') {
        throw new Error('It should scan a slp address')
      }
      // Validates that the words "simpleledger" are contained
      let isValid = false
      for (let i = 0; i < validateAdrrs.length; i++) {
        isValid = isValid ? true : data.match(validateAdrrs[i])
        if (isValid) {
          _this.setState({
            address: data,
            errMsg: ''
          })
          //  const addressEle = document.getElementById('addressToSend')
          // addressEle.value = data
        }
      }
      if (!isValid) {
        throw new Error('It should scan a slp or bch address')
      }
      if (_this.isBchAddr(data)) {
        _this.setState({
          showWarning: true
        })
      } else {
        _this.setState({
          showWarning: false
        })
      }
      _this.changeSection('send')
    } catch (error) {
      _this.changeSection('send')

      _this.setState({
        errMsg: error.message
      })
    }
  }

  resetAddressValue() {
    _this.setState({
      address: '',
      errMsg: '',
      txid: ''
    })
    //const addressEle = document.getElementById('addressToSend')
    //addressEle.value = ''
  }

  setError(errMsg) {
    _this.setState({
      errMsg
    })
  }
  changeSection(section) {
    _this.setState({
      section
    })
  }

  refreshTokens() {
    try {
      const { txid } = _this.state
      if (!txid) return
      setTimeout(() => {
        const elem = document.getElementById('refresh-mytokens')
        elem.click()
      }, 2000);
    } catch (error) {
      console.error(error.message)
    }
  }
  isSlpAddr(address) {
    const isValid = address.match('simpleledger')
    return isValid
  }
  isBchAddr(address) {
    const isValid = address.match('bitcoincash')
    return isValid
  }
  async handleExportOption(externalExport) {
    try {
      // Change to the 'send' view to add the destination address
      if (externalExport) {
        _this.changeSection('send')
        return
      }
      // Search the user data
      const userData = _this.props.userData
      const user = getUser()

      let slpAddress
      if (userData.addresses && user._id === userData._id) {
        slpAddress = userData.addresses.slpAddress
      }
      // If user slp address is not found locally
      // make the petition to the api to search it
      if (!slpAddress) {
        slpAddress = await _this.getSlpAddress()
      }

      // send token user to the user's slp address
      await _this.handleSend(slpAddress)

    } catch (error) {
      _this.setState({
        isLoaded: true
      })
      _this.setError(error.message)
    }
  }

  async getSlpAddress() {
    // Get Addresses
    try {
      _this.setState({
        isLoaded: false,
      })
      const user = getUser()
      const result = await getUserAddrs(user._id)

      const userD = _this.props.userData
      userD.addresses = result.addresses
      userD._id = user._id

      _this.props.setUserData(userD)

      return result.addresses.slpAddress
    } catch (error) {
      throw error
    }
  }
}
Export.propTypes = {
}
