/**
 *
 * This component generates and renders all tokens
 * 
 */
import React from "react"
// eslint-disable-next-line
// eslint-disable-next-line
import { Row, Col, Box } from "adminlte-2-react"
import { getProjectTokens, getTokenIcon } from "../../services/token"
import TokenCard from './token-cards'
// import PropTypes from "prop-types"
// import { PayloadCards } from "./payload-cards"

let _this
export class ProjectTokens extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      projectId: '',
      tokens: []
    }
    _this = this
  }
  render() {
    const { loaded, tokens } = _this.state

    return (
      <div className=' mt-2'>
        {!loaded && <Box className="border-none loading-box text-center" loaded={loaded} ><h3 className="mb-3">{_this.props.selectedProject.projectLabel} Tokens!</h3></Box>
        }
        {loaded && tokens.length > 0 && (
          <>
            <Row >
              {tokens.map((val, i) => {
                if (val.qty > 0) {
                  return (
                    <Col sm={4} key={`token-${i}`}>
                      <TokenCard
                        key={`token-${i}`}
                        id={`token-${i}`}
                        token={val}
                        hideRedeemBtn={true}
                        hideInfoBtn={false}
                        navigateToProject={_this.props.navigateToProject}
                        {..._this.props}
                      />
                    </Col>
                  )
                } else {
                  return <span key={`token-${i}`} />
                }
              })}
            </Row>
          </>
        )}
        {(loaded && !tokens.length) && <Box className={"text-center"}>Tokens Not Found!</Box>}
        {/** hide button for refresh tokens */}
        {/** eslint-disable-next-line */}
        <button className='display-none' id='refresh-mytokens' onClick={_this.refresh}>refresh</button>
      </div>

    )
  }
  async componentDidMount() {
    // await _this.getTokens()
  }
  async getTokens() {
    // Get Addresses
    try {
      _this.handleLoaded(false)
      const { selectedProject } = _this.props
      const result = await getProjectTokens(selectedProject._id)

      if (!result.success) {
        _this.setState({
          errMsg: 'Tokens cant be found!'
        })
        _this.handleLoaded(true)

        return
      }

      const tokens = await _this.getTokenIcons(result.tokens)

      // console.log('tokens', tokens)

      // refresh projects tokens array
      await _this.props.getProjects()


      _this.setState({
        tokens: tokens,
        loaded: true
      })

      //const userD = _this.props.userData
      // userD.addresses = result.addresses
      // _this.props.setUserData(userD)
    } catch (error) {
      // console.warn(error)
      _this.handleLoaded(true)

    }
  }

  async componentDidUpdate() {
    if (_this.props.selectedProject._id !== _this.state.projectId) {
      _this.setState({
        projectId: _this.props.selectedProject._id
      })
      await _this.getTokens()
    }
  }
  handleLoaded(value) {
    _this.setState({
      loaded: value,
    })
  }
  async refresh() {
    await _this.getTokens()

  }
  async getTokenIcons(tokens) {
    try {
      const _tokens = []
      for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i]
        let icon
        try {
          icon = await getTokenIcon(token.tokenId)
        } catch (error) {
          icon = ''
        }
        token.icon = icon
        _tokens.push(token)

      }
      return _tokens
    } catch (error) {
      console.log(error)
    }
  }
}