/* eslint-disable */
/**
 * This component renders a modal with the details of a
 * specific payload in the 'shared with me' view
 */
import React from "react"
import PropTypes from "prop-types"
import { downloadFiles, updatePayload } from "../../../services/payload"
import { updateMetadata } from "../../../services/metadatas"
import { getUser } from "../../../services/auth"

import { Content, Box, Button, Inputs } from "adminlte-2-react"

import { NotificationManager } from "react-notifications"

import jpg from "../../../images/thumbnails/jpg-svgrepo-com.svg"
import doc from "../../../images/thumbnails/word.svg"
import pdf from "../../../images/thumbnails/pdf.svg"
import png from "../../../images/thumbnails/png.svg"
import rar from "../../../images/thumbnails/rar.svg"
import txt from "../../../images/thumbnails/txt.svg"
import zip from "../../../images/thumbnails/zip.svg"
import unknow from "../../../images/thumbnails/unknow.svg"
const { Text } = Inputs

// Representative images for file extensions
const extensionsUrl = {
  pdf: pdf,
  doc: doc,
  docx: doc,
  png: png,
  jpg: jpg,
  jpeg: jpg,
  rar: rar,
  txt: txt,
  zip: zip,
  unknow: unknow,
}

let _this

/*
  The variable 'activeCustomThumbnail' is a flag that
  allows us to show or hide the custom upload thumbnail button
  in this section of the modal
  Link to the trello task with the instructions --> https://trello.com/c/jkY7rSSr
*/
const activeCustomThumbnail = false

export class SharedPayloadModal extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      loaded: true,
      payloads: [],
      selectedPayload: {},
      time: "",
      payloadFilesData: "",
      archivedCheckBox: false,
      loadedFilePreview: "",
      filePreviewErrorTxt: "",
      thumbnailName: "",
      thumbnailInFetch: false,
      user: getUser(),
    }
  }

  render() {
    const { currentModal, hideCurrentModal } = _this.props
    const { selectedPayload } = _this.state

    return (
      <>
        <Content
          className="disclosure-form-container "
          modal
          modalCloseButton={true}
          onHide={hideCurrentModal}
          show={currentModal === "shared payload details"}
          modalSize="lg"
          /* Modal Header */
          title={
            <React.Fragment>
              <div className="tx-header">
                <div className="tx-time-content">
                  <p className="tx-time">{this.state.time}</p>
                  <Box id="tx-time-ico" icon="fa-unlock"></Box>
                </div>

                <p className="tx-signature">Digital Signature : </p>

                <p className="tx-id" onClick={this.openExplorer}>
                  Tx:
                  <u>
                    {this.state.selectedPayload.jsonTxId
                      ? this.state.selectedPayload.jsonTxId
                      : ""}
                  </u>
                </p>
                <p className="ipfs-hash">
                  IPFS Hash:
                  <span>
                    {this.state.selectedPayload.ipfsFileRetrievalHash
                      ? this.state.selectedPayload.ipfsFileRetrievalHash
                      : ""}
                  </span>
                </p>
              </div>
            </React.Fragment>
          }
          /*Modal Footer*/
          modalFooter={
            <React.Fragment>
              <Box className="tx-footer-box" loaded={this.state.loaded}>
                <div className="payload-card-footer">

                  <div className="footer-box-buttons">
                    <Button
                      type="primary"
                      text={_this.state.selectedPayload.isArchived ? 'Unarchive' : 'Archive'}
                      onClick={_this.archivePayload}
                    />
                    <Button
                      type="primary"
                      text="Start Chat"
                      onClick={_this.startChat}
                    />

                    {/* <Button
                      type="primary"
                      text="New Version"
                      onClick={this.newVersion}
                    /> */}
                    <Button type="primary" text="Proof" onClick={this.proof} />
                    <Button
                      type="primary"
                      text="View File"
                      onClick={this.showFile}
                    />
                    <Button
                      type="primary"
                      text="Download"
                      onClick={this.fetchFiles}
                    />
                  </div>
                </div>
              </Box>
            </React.Fragment>
          }
        >
          {/*Modal Body*/}
          {
            <>
              <div className="tx-body">
                <h2>
                  {_this.state.selectedPayload.title
                    ? _this.state.selectedPayload.title
                    : ""}
                </h2>
              </div>
              <Box
                loaded={!_this.state.thumbnailInFetch}
                className="extensions-thumbnails"
              >
                {_this.state.payloadFilesData.length > 0 && (
                  <h4>Disclosure Content.</h4>
                )}
                {//!_this.state.thumbnailInFetch &&
                  //  // temporarily set to use the default
                  //  // icons until the thumbnails created in this task -->
                  //  //  https://trello.com/c/Bo2Udccy can be retrieved
                  //  (false ? ( //_this.state.filePreview ?
                  //    <img
                  //      className=""
                  //      height="80"
                  //      src={_this.state.filePreview}
                  //    />
                  //  ) : (
                  //    _this.state.payloadFilesData &&
                  //    _this.state.payloadFilesData.map((val, i) => {
                  //      return (
                  //        <img
                  //          key={i}
                  //          className=""
                  //          height="80"
                  //          width="80"
                  //          src={extensionsUrl[val] || unknow}
                  //        />
                  //      )
                  //    })
                  //  ))
                }
                
                {selectedPayload._id &&
                  <>
                    {selectedPayload.meta.filePreview && selectedPayload.meta.thumbnailIpfsUrl ? (
                      <img
                        // temporarily set to use the default
                        // icons until the thumbnails created in this task -->
                        // https://trello.com/c/Bo2Udccy can be retrieved
                        src={selectedPayload.meta.thumbnailIpfsUrl} /* src={val.meta.filePreview} */
                        className=""
                        alt="thumbnail"
                        width="200"
                      />
                    ) : (
                      <img
                        alt="thumbnail"
                        className=""
                        width="200"
                        src={
                          extensionsUrl[selectedPayload.meta.fileData[0]] || unknow
                        }
                      />

                    )}
                  </>
                }
              </Box>

              {/*
                The variable 'activeCustomThumbnail' is a flag that
                allows us to show or hide the custom upload thumbnail button
                in this section of the modal
                Link to the trello task with the instructions --> https://trello.com/c/jkY7rSSr
              */}
              {activeCustomThumbnail && (
                <div>
                  {_this.state.loadedFilePreview ? (
                    <div className="updateThumbnails-inputs">
                      <Button
                        type="primary"
                        icon="fa-times"
                        onClick={_this.resetThumbnailInput}
                      />
                      <Button
                        type="primary"
                        text="Update"
                        onClick={_this.updatePayloadThumbnail}
                      />
                    </div>
                  ) : (
                    <div className="loadThumbnails-inputs">
                      {/* this text inputs is hiden */}
                      <Text
                        id="thumbnail-input"
                        name="thumbnail-input"
                        labelPosition="none"
                        inputType="file"
                        onChange={_this.handleInputThumbnail}
                      />
                      <Button
                        type="primary"
                        text="Upload Custom Icon"
                        onClick={_this.handleFileBrowser}
                      />
                    </div>
                  )}
                </div>
              )}

              {_this.state.thumbnailName && (
                <p className="thumbnail-name">
                  Name: {_this.state.thumbnailName}
                </p>
              )}
              {_this.state.filePreviewErrorTxt && (
                <p className="text-red">{_this.state.filePreviewErrorTxt}</p>
              )}
            </>
          }
        </Content>
      </>
    )
  }

  handleFileBrowser() {
    const fileInput = document.getElementsByName("thumbnail-input")
    fileInput[0].click()
  }
  resetThumbnailInput() {
    document.getElementsByName("thumbnail-input").value = null
    const payload = _this.state.selectedPayload
    _this.setState({
      loadedFilePreview: null,
      filePreviewErrorTxt: "",
      thumbnailName: "",
      filePreview: payload.meta.filePreview,
    })
  }
  // update payload
  async updatePayloadThumbnail() {
    try {
      const payload = _this.state.selectedPayload
      const filePreview = _this.state.loadedFilePreview
      if (!filePreview) return

      _this.setState({
        thumbnailInFetch: true,
      })
      const meta = payload.meta
      meta.filePreview = filePreview
      const toUpdate = {
        _id: payload._id,
        meta,
      }
      const updateResult = await updatePayload(toUpdate)
      if (!updateResult || !updateResult.payload) {
        throw new Error("Error Udating Thumbnail")
      }

      const payloads = _this.props.sharedWithMe
      // update payloads array  , property isArchived
      payloads.map((val, i) => {
        if (val._id === payload._id) {
          payloads[i].meta.filePreview = filePreview
        }
      })

      _this.props.setSharedWithMe(payloads)
      _this.setState({
        thumbnailInFetch: false,
      })
      _this.resetThumbnailInput()
    } catch (error) {
      NotificationManager.error("Error Udating Thumbnail", "Error")
      _this.setState({
        thumbnailInFetch: false,
      })
    }
  }
  handleInputThumbnail(event) {
    try {
      const name = event.target.name
      const file = event.target.files[0]
      if (!file) throw new Error("Thumbnail file not found!")

      _this.setState({
        thumbnailName: file.name,
      })

      if (!file.type.includes("image"))
        throw new Error("Thumbnail must be a image type!")

      // Get base64 file url
      var reader = new FileReader()
      reader.onload = function (e) {
        _this.setState({
          loadedFilePreview: reader.result,
          filePreviewErrorTxt: "",
          thumbnailName: file.name,
          filePreview: reader.result,
        })
      }
      reader.readAsDataURL(file)
    } catch (error) {
      _this.setState({
        filePreviewErrorTxt: error.message || "Error loading file!",
      })
    }
  }
  handleEmailCheck(emailCheck) {
    _this.setState({
      emailCheck,
    })
  }
  handleUpdate(event) {
    const name = event.target.name
    const value = event.target.value
    _this.setState(prevState => ({
      ...prevState,
      formValues: {
        ...prevState,
        [name]: value,
      },
    }))
  }

  // Update State
  async componentDidUpdate() {
    if (_this.props.selectedPayload !== _this.state.selectedPayload) {
      const payload = _this.props.selectedPayload

      console.log("Selected Payload", payload)

      let fileData = []
      let isArchived = false
      let filePreview = null

      if (payload) {
        fileData =
          payload.meta && payload.meta.fileData ? payload.meta.fileData : []
        isArchived = payload.isArchived

        // Verify if thumbnail exist
        if (payload.meta && payload.meta.filePreview) {
          filePreview = payload.meta.filePreview
        }
      }

      _this.setState({
        selectedPayload: payload,
        payloadFilesData: fileData,
        archivedCheckBox: isArchived,
        loadedFilePreview: null,
        filePreview,
      })
      setTimeout(() => {
        _this.getTime()
      }, 100)
    }

    setTimeout(() => {
      try {
        const modal = document.getElementsByClassName("modal-dialog")
        if (modal[0]) modal[0].id = "modalTx"
      } catch (error) {
        console.error(error)
      }
    }, 50)
  }
  // Parse timestamp unix to Date
  getTime() {
    let time
    try {
      const unixTime = _this.state.selectedPayload.createdTimestamp
      if (!unixTime) throw new Error()
      const createdTimestamp = new Date(unixTime * 1000)
      time = createdTimestamp.toString()
    } catch (error) {
      time = ""
    }
    _this.setState({
      time: time,
    })
  }

  openExplorer() {
    window.open(
      `https://explorer.bitcoin.com/bch/tx/${_this.state.selectedPayload.jsonTxId}`,
      "_blank"
    )
  }
  //Enable / Disable loading spinner
  switchSpinner(value) {
    _this.setState({
      loaded: value,
    })
  }
  async fetchFiles() {
    try {
      _this.switchSpinner(false)
      const result = await downloadFiles(_this.state.selectedPayload)
      if (!result) {
        throw new Error("Error Fetching files data")
      }
      const reader = result.body.getReader()
      await _this.startDownloadfiles(reader)
      //console.log('metadata',_this.state.metadata)
      await _this.logFileAccess("download")

      _this.switchSpinner(true)
      _this.props.hideCurrentModal()
    } catch (error) {
      _this.switchSpinner(true)
      NotificationManager.error("Download Error", "Error")
    }
  }

  // Set file data to blob type
  // Create html code to start file download
  async startDownloadfiles(reader) {
    try {
      if (!reader) {
        throw new Error("Error starting download")
      }

      let chunks = [] // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          break
        }

        chunks.push(value)
      }

      // Create html element
      // This element will contain the link
      // to start the download
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.style = "display: none"

      const fileName = _this.state.selectedPayload.title
        ? _this.state.selectedPayload.title
        : _this.state.selectedPayload._id

      const fileExt = ".zip"

      const blob = new Blob(chunks)
      const url = window.URL.createObjectURL(blob)

      a.href = url
      a.download = fileName + fileExt
      a.click()
      window.URL.revokeObjectURL(url)
      NotificationManager.success("Download Started", "Success", 2000)
    } catch (error) {
      throw error
    }
  }

  resetValues() {
    _this.setState({
      emailFormData: "",
      emailList: [],
      message: "",
    })
  }

  // update payload
  async archivePayload() {
    try {
      const payload = _this.state.selectedPayload
      const toUpdate = {
        _id: payload._id,
        isArchived: !payload.isArchived,
      }
      const updateResult = await updatePayload(toUpdate)
      console.log("updateResult", updateResult)

      if (!updateResult || !updateResult.metadata) {
        throw new Error("Error trying to archive document")
      }

      const payloads = _this.props.sharedWithMe
      // update payloads array  , property isArchived
      payloads.map((val, i) => {
        if (val._id === payload._id) {
          payloads[i].isArchived = toUpdate.isArchived
        }
      })

      _this.props.setSharedWithMe(payloads)

      let output = toUpdate.isArchived
        ? "Document Archived!"
        : "Document Unarchived!"

      NotificationManager.success(output, "Success", 2000)
      _this.props.hideCurrentModal()
    } catch (error) {
      console.error(error)
      NotificationManager.error("Error trying to archive document", "Error")
    }
  }

  validateEmailRepeat() {
    return _this.state.emailList.includes(_this.state.emailFormData)
  }
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }

  proof() {
    const originUrl = window.location.origin
    const payloadId = _this.state.selectedPayload._id
    const url = `${originUrl}/proof/?id=${payloadId}`
    window.open(url, "_blank")
  }

  async showFile() {
    const originUrl = window.location.origin
    const payloadId = _this.state.selectedPayload._id
    const url = `${originUrl}/showfiles/?id=${payloadId}`
    window.open(url, "_blank")
    await _this.logFileAccess("view")
  }

  async logFileAccess(type) {
    try {
      const { _id, fileAccess } = _this.state.selectedPayload
      const newAccess = {
        timestamp: Math.floor(new Date().getTime() / 1000),
        userId: getUser()._id,
        action: type,
      }
      fileAccess.push(newAccess)

      const metadata = {
        _id: _id,
        fileAccess: fileAccess,
      }
      const updateResult = await updatePayload(metadata)
      // Finds the payload index associated
      const payloads = _this.props.payloads
      const associatedPayloadIndex = payloads.findIndex(
        val => val._id === selectedPayload._id
      )
      // Update local payload
      payloads[associatedPayloadIndex] = updateResult.metadata
    } catch (error) {
      console.log(error)
    }
  }

  newVersion() {
    _this.props.addNewVersion(_this.props.selectedPayload)
  }

  startChat() {
    _this.props.showModal("chat")
  }
}

SharedPayloadModal.propTypes = {
  addNewVersion: PropTypes.func,
}
