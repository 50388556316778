import "isomorphic-fetch"
import { getUser } from "./auth"
const axios = require('axios').default

const SERVER = process.env.GATSBY_API_URL
const GATEWAY =process.env.IPFS_GATEWAY

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined"

export const tokenizePayload = async (payloadId, customData) => {
    const token = getUser().jwt ? getUser().jwt : ""
  
    // Try to get  payload by id
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          payloadId,
          customData
        }),
      }
      const resp = await fetch(`${SERVER}/token/tokenize-payload`, options)
      if (resp.ok) {
        return resp.json()
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  export const getProjectTokens = async projectId => {
    const token = getUser().jwt ? getUser().jwt : ""
  
    // Try to get  payload by id
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          projectId,
        }),
      }
      const resp = await fetch(`${SERVER}/token/project-tokens`, options)
      if (resp.ok) {
        return resp.json()
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  export const getTokenData = async tokenIds => {
    const token = getUser().jwt ? getUser().jwt : ""
  
    // Try to get  payload by id
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tokenIds,
        }),
      }
      const resp = await fetch(`${SERVER}/token/data`, options)
      if (resp.ok) {
        return resp.json()
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  export const redeemToken = async ( tokenId) => {
    const token = getUser().jwt ? getUser().jwt : ""
  
    //try to get all projects
    try {
      const options = {
        method: 'POST',
        url: `${SERVER}/token/redeem`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          tokenId,
        }
      }
      const data = await axios(options)
      const txids = data.data //await data.json()
      return txids.result[0]
    } catch (e) {
      throw e
    }
  }

  export const fetchIpfs = async ( url) => {
  
    //try to get all projects
    try {

      const result = await axios.get(url)
      return result.data
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  export const getTokenIcon = async (tokenId ) => {
    const token = getUser().jwt ? getUser().jwt : ""
  
    //try to get token icon
    try {
      const options = {
        method: 'GET',
        url: `${SERVER}/token/icon/${tokenId}`,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
      const result = await axios(options)
      const res = result.data //await data.json()
      const url = `${GATEWAY}${res.result}`
      return url
    } catch (e) {
      throw e
    }
  }
  
  