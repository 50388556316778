/**
 *
 * This component generates and renders help view
 *
 */

// Note :
// Video Link -> https://www.youtube.com/watch?v=f1no5-QHTr4
// Video ID -> f1no5-QHTr4
import React from "react"
import { EmbedCard } from "./embed-card"
import { Row, Col } from "adminlte-2-react"

let _this
export class Help extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			embedList: ["PGSflCbFvlg", "wzijWF1TlFQ" , "MfMgPBHrX84", "yMxKloOvbak"] 
		}
		_this = this
	}
	render() {
		const { embedList } = _this.state
		return (
			<Row>
				{embedList.map((val, i) => {
					return <Col sm={4} key={`embed-help-${i}`}>
						<EmbedCard embedId={val} />
					</Col>
				})}

			</Row>

		)
	}

}
