/**
 *
 * 
 * 
 */
import React from "react"
// eslint-disable-next-line
// eslint-disable-next-line
import { Row, Col, Content, Box, DataTable } from 'adminlte-2-react'
import { getUserById } from '../../services/users'
import { getUser } from "../../services/auth"
import { getPayloadByIdBULK , downloadFiles , updatePayload} from "../../services/payload"
import { NotificationManager } from "react-notifications"

// import PropTypes from "prop-types"
// import { PayloadCards } from "./payload-cards"

let _this
export class Downloads extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loaded: false
    } // 
    this.firstColumns = [
      { title: 'Created At',
       data: 'createdTimestamp' ,
       render: val => (
        <span >
        { new Date(val * 1000).toString()}
        </span>
      )
    },
    
      {
        title: 'Title',
        data: 'title',
      },
      {     
        title: 'Action',
        data: '_id',
        render: val => (
          <span className='on-click-event action-handler'>
           Download
          </span>
        )
      },
    ]
    _this = this
  }
  render() {
    const { loaded, data } = _this.state
    return (
      <Row>
        <Col
          xs={12}
          md={12}
        >
        <Box loaded={loaded} padding="true" className="box-payload-table">

            <DataTable
              columns={_this.firstColumns}
              data={data}
              options={{
                paging: true,
                lengthChange: false,
                searching: false,
                ordering: false,
                info: true,
                autoWidth: false
              }}
              onClickEvents={{
                onClickEvent: (data, rowIdx, rowData) => {
                  _this.handleEvents(rowData)
                }
              }}
            />

          </Box> 
        </Col>
      </Row>

    )
  }

  async componentDidMount() {
    await _this.getDownloads()
  }
  // refresh user data
  async getDownloads(){
    try {
      const user = getUser()

      const userId = user._id

      const userData = await getUserById(userId)

      const downloads = userData.user.downloads

      let payloadsData = []
      let offset = 0
      const limit = 20
  
      while (offset < downloads.length) {

  
        const payloadsToFetch = downloads.slice(offset, offset + limit)
        const payloads = await getPayloadByIdBULK(payloadsToFetch)
        payloadsData = payloadsData.concat(payloads.metadatas)
  
        offset = offset + limit
      }
      _this.setState({
        data : payloadsData,
        loaded:true
      })
    } catch (err) {
      console.warn('Error in getDownloads() ', err)

    }
  }
  // download functionality
  async handleEvents(eventData) {
    try {
      await _this.fetchFiles(eventData)
    } catch (err) {
      console.warn('Error in handleEvents() ', err)
    }
  }

async fetchFiles(payload) {
    try {
      _this.switchSpinner(false)
      const result = await downloadFiles(payload)
      if (!result) {
        throw new Error("Error Fetching files data")
      }
      const reader = result.body.getReader()
      await _this.startDownloadfiles(reader, payload)
      await _this.logFileAccess("download" , payload)

      _this.switchSpinner(true)
      _this.props.hideCurrentModal()
    } catch (error) {
      _this.switchSpinner(true)
      NotificationManager.error("Download Error", "Error")
    }
  }

  // Set file data to blob type
  // Create html code to start file download
  async startDownloadfiles(reader, payload) {
    try {
      if (!reader) {
        throw new Error("Error starting download")
      }

      let chunks = [] // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          break
        }

        chunks.push(value)
      }

      // Create html element
      // This element will contain the link
      // to start the download
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.style = "display: none"

      const fileName = payload.title
        ? payload.title
        : payload._id

      const fileExt = ".zip"

      const blob = new Blob(chunks)
      const url = window.URL.createObjectURL(blob)

      a.href = url
      a.download = fileName + fileExt
      a.click()
      window.URL.revokeObjectURL(url)
      NotificationManager.success("Download Started", "Success", 2000)
    } catch (error) {
      throw error
    }
  }

  async logFileAccess(type, payload) {
    try {
      const { _id, fileAccess } = payload
      const newAccess = {
        timestamp: Math.floor(new Date().getTime() / 1000),
        userId: getUser()._id,
        action: type,
      }
      fileAccess.push(newAccess)

      const metadata = {
        _id: _id,
        fileAccess: fileAccess,
      }
      await updatePayload(metadata)
    } catch (error) {
    }
  }
    //Enable / Disable loading spinner
    switchSpinner(value) {
      _this.setState({
        loaded: value,
      })
    }
}
