/* eslint-disable */
/**
 * This is the component in charge of rendering a modal
 * with the form to add new payloads and new
 * existent payload versions
 */
import React from "react"
import PropTypes from "prop-types"
import {
  getPayloadByProject,
  updatePayload,
  newMacroPayload,
} from "../../../services/payload"
import { getUser } from "../../../services/auth"

import { NotificationManager } from "react-notifications"
// Uppy imports
const Uppy = require("@uppy/core")
const Tus = require("@uppy/tus")
const { Dashboard } = require("@uppy/react")
// const DragDrop = require("@uppy/drag-drop")
// const Webcam = require("@uppy/webcam")
// const GoogleDrive = require("@uppy/google-drive")
// const Dropbox = require("@uppy/dropbox")

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "@uppy/webcam/dist/style.css"
import {
  isRequired,
  isNotInRange,
  containsWhiteSpace,
} from "react-form-validators"
const methods = [containsWhiteSpace, isNotInRange]

import { Content, Button, Inputs, Box } from "adminlte-2-react"

const { Text, Checkbox } = Inputs

const SERVER = process.env.GATSBY_API_URL

//instantiate uppy
const uppy = Uppy({
  meta: { test: "avatar" },
  allowMultipleUploads: true,
  debug: false,
  restrictions: {
    maxFileSize: null,
    maxNumberOfFiles: 2,
    minNumberOfFiles: 1,
    allowedFileTypes: null, //type of files allowed to load
  },
  onBeforeUpload: files => {
    const updatedFiles = Object.assign({}, files)
    Object.keys(updatedFiles).forEach(fileId => {
      const indexName = fileId.lastIndexOf("/")
      const fileName = fileId.substring(indexName, fileId.length)
      uppy.setFileMeta(fileId, { fileNameToEncrypt: fileName })
    })
    return updatedFiles
  },
})

// Adding plugins
uppy.use(Tus, {
  endpoint: `${SERVER}/files`,
  locale: {
    strings: {
      browse: "выберите ;-)",
    },
  },
})

// uppy.use(Webcam)
// uppy.use(GoogleDrive, { companionUrl: 'https://companion.uppy.io' })
// uppy.use(Dropbox, { companionUrl: 'https://companion.uppy.io' })

uppy.on("complete", result => {
  //
})

let _this

export class NewPayload extends React.Component {
  constructor(props) {
    super(props)

    _this = this

    this.state = {
      show: false,
      loaded: true,
      formValues: {
        title: "",
        isNDA: false,
      },
      payloads: [],
      project: {},
      fileData: [],
      previousVersion: {},
      filePreview: "",
      encrypt: 'encrypt'
    }

    this.uppy = uppy
  }

  render() {
    const { currentModal } = _this.props

    // Validators Errors
    // Add all validator errors into modal footer submit button disabled property
    let titleError = isRequired(_this.state.formValues.title)
    const { previousVersion } = _this.state
    return (
      <>
        <Content
          className="disclosure-form-container"
          title={
            previousVersion._id
              ? "Add New Document Version"
              : "Add New Document"
          }
          modal
          // eslint-disable-next-line
          modalCloseButton={true}
          modalCloseButton={false}
          modalFooter={
            <React.Fragment>
              <Box
                className="disclosure-form-box-loader"
                loaded={_this.state.loaded}
              >
                <Button text="Close" pullLeft onClick={_this.resetValues} />
                <Button
                  type="primary"
                  text="Submit to Blockchain"
                  onClick={this.submitData}
                  disabled={titleError}
                />
              </Box>
            </React.Fragment>
          }
          show={currentModal === "new payload"}
        >
          <Text
            type={titleError ? "error" : "success"}
            help={titleError}
            id="title"
            name="title"
            placeholder="Enter Title"
            label="TITLE"
            labelPosition="above"
            onChange={this.handleUpdate}
          />

          <b>THIS IS AN AGREEMENT</b>
          <Box className="checkbox-container">
            <Checkbox
              value={_this.state.formValues.isNDA ? true : false} // mark as checked
              text={_this.state.formValues.isNDA ? "Enabled" : "Disabled"}
              labelPosition="none"
              labelXs={0}
              name="isNDA"
              onChange={this.handleUpdate}
            />
          </Box>

          <Dashboard
            uppy={uppy}
            plugins={["Tus", "Webcam", "Dropbox", "GoogleDrive"]}
            thumbnailWidth={512}
          />
          {/*   <Box className="disclosure-file" >
                        <Row>
                            <Col xs={12}>
                                <span >Drag one or more files with an image,
                                       sound, video or pdf file.</span>
                            </Col>
                            <Col xs={12}>
                                <Button
                                    type='primary'
                                    icon='fasplus'
                                    text='Add' />
                            </Col>
                        </Row>
                    </Box> */}
          <Text
            inputType="textarea"
            label="ABSTRACT"
            labelPosition="above"
            rows={5}
          />
          {/*       <b>INSTITUTIONAL SHARING</b>
          <Box className="checkbox-container">
            <Checkbox text="Enable" labelPosition="none" labelXs={0} />
            <span className="text-red">
              Enabling this features allows all users with your company email
              domain to view this Project
            </span>
          </Box> */}
          <b>GUEST SHARING</b>
          <Box className="checkbox-container">
            <Checkbox
              value={true} // mark as checked
              text="Enable"
              labelPosition="none"
              labelXs={0}
              disabled
            />
            <span>
              Allows you to share a document with anyone. Please consider
              executing a sharing agreement before sharing.
            </span>
          </Box>

          <Text
            name="keywords"
            placeholder="Write a Keyword"
            label="KEYWORDS"
            labelPosition="above"
            buttonRight={<Button type="primary" text="Add" />}
          />
          <div className="checkbox-container">
            <form name="radioInputs" className="radioInputs">
              <input
                type="radio"
                id="encrypt-radio"
                name="encrypt"
                onClick={()=>this.changeEncrypt('encrypt')}
                defaultChecked
              />
              <span>Encrypt</span>
              <br></br>
              <input
                type="radio"
                id="no-encrypt-radio"
                name="encrypt"
                onClick={()=>this.changeEncrypt('noEncrypt')}

              />
              <span>
                Do Not Encrypt
              </span>
            </form>
          </div>
        </Content>
      </>
    )
  }

  handleUpdate(event) {
    const name = event.target.name
    let value = event.target.value
    if (name === "isNDA") {
      value = !_this.state.formValues.isNDA
    }
    const formValues = _this.state.formValues
    formValues[name] = value

    _this.setState(prevState => ({
      formValues,
    }))


  }
  handleUppyThumbnail() {
    this.uppy.on("thumbnail:generated", async (file, preview) => {
      try {
        if (!_this.state.filePreview) {
          // Get thumbnail blob
          let blob = await fetch(preview).then(r => r.blob())
          _this.setState({
            filePreview: blob,
          })

          // Add thumbnail file to uppy
          uppy.addFile({
            name: `thumbnail-${file.name}`, // file name
            type: file.data.type, // file type
            data: blob, // file blob
          })
        }

        // Convert file to base64 format (Example)
        /*       try {
                var reader = new FileReader()
                reader.onload = function(e) {
                  console.log("reader.result")
        
                  _this.setState({
                    filePreview: reader.result,
                  })
                }
                reader.readAsDataURL(file.data)
              } catch (error) {
                console.error(error)
              } */
      } catch (error) {
        console.error(error)
      }
    })
  }
  async componentDidMount() {
    console.log('new payload modal')
    _this.getPayloads()
    _this.handleUppyThumbnail()

    // Remove thumbnail when the main file is removed
    uppy.on("file-removed", (file, reason) => {
      uppy.reset()
      _this.setState({
        filePreview: "",
      })
    })
  }
  async getPayloads(project) {
    try {
      
      //console.log(_this.props.getPayloads)
      if (project && !project._id) return
      const payloads = await _this.props.getPayloads(project)

      console.log("getPayloads", payloads)
      _this.props.setPayloads(payloads.metadatas)
    } catch (error) {
      console.error(error)
    }
  }

  // The text of the uppy dashboard comes from the library,
  // to change  it i had to create a function and use JS
  changeUppyDashboardText() {
    try {
      const newText = "Drop file here, or "

      const uppyTextElement = document.getElementsByClassName(
        "uppy-Dashboard-AddFiles-title"
      )
      if (uppyTextElement[0]) {
        const childs = uppyTextElement[0].childNodes
        const textElement = childs[0]
        textElement.nodeValue = newText
      }
    } catch (error) {
      console.warn(error)
    }
  }
  componentDidUpdate() {
    if (_this.props.currentModal === "new payload") {
      setTimeout(() => {
        _this.changeUppyDashboardText()
      }, 30)
    }
    if (_this.props.project !== _this.state.project) {
      _this.setState({
        project: _this.props.project,
      })
      _this.getPayloads(_this.props.project)
    }
    if (_this.props.previousVersion !== _this.state.previousVersion) {
      _this.setState({
        previousVersion: _this.props.previousVersion,
      })
      // debugger
    }
  }

  componentWillUnmount() {
    // Reset uppy default state
    this.uppy && this.uppy.reset()
  }

  // Click handler for the 'Submit to Blockchain' button.
  async submitData() {
    let resultPayload
    try {
      _this.switchSpinner(false)

      // Array of encryption files names
      // This is sent to the backend to verify if it exist.
      // Tracks the server-side file names for referencing them after the upload
      // completes.
      let encryptionFileName
      let thumbnailFileName
      // Get file name from uppy state, and push it into the array.
      const uppyState = uppy.getState()
      // console.log(
      //   `uppyState.files: ${JSON.stringify(uppyState.files, null, 2)}`
      // )
      Object.keys(uppyState.files).forEach(fileId => {
        //const indexName = fileId.lastIndexOf("/")
        //const fileName = fileId.substring(indexName, fileId.length)

        // console.log(`fileId: ${JSON.stringify(fileId,null,2)}`)
        // debugger

        // Add the uploaded file name to the array.
        // the first object contains the original file name
        // the second object contains the thumbnail name
        if (encryptionFileName) {
          thumbnailFileName = uppyState.files[fileId].name
        } else {
          encryptionFileName = uppyState.files[fileId].name
        }
      })

      // console.log(`encryptionFileName: ${encryptionFileName}`)
      // console.log(`thumbnailFileName: ${thumbnailFileName}`)

      //uppy error handler
      await _this.uppyHandler()

      // Register a payload for the thumbnail if it exists
      let macroThumbnailResult
      if (thumbnailFileName) {
        // Create a new metadata model for thumbnail (locally)
        const thumbnailMetadata = _this.createMetadataModel()

        // set info to metadata model
        thumbnailMetadata.title = `${thumbnailFileName}`
        thumbnailMetadata.isThumbnail = true // Mark this payload as thumbnail

        // Use the /macro/metadata endpoint to process the thumbnail payload.
        const ignoreEncryption = true
        macroThumbnailResult = await newMacroPayload(
          thumbnailMetadata,
          thumbnailMetadata.title,// File Reference
          ignoreEncryption // ignore encryption file
        )
        if (!macroThumbnailResult || !macroThumbnailResult.success) {
          throw new Error("Document creation failed!")
        }
      }

      // Create a new metadata model (locally)
      const metadata = _this.createMetadataModel()

      if (macroThumbnailResult) {
        metadata.meta.filePreview = macroThumbnailResult.metadataId
      }

      // Use the /macro/metadata endpoint to process the new payload.
      const ignorePayloadEncrypt = _this.state.encrypt === 'encrypt' ? false : true
      const macroResult = await newMacroPayload(metadata, encryptionFileName , ignorePayloadEncrypt)

      if (!macroResult || !macroResult.success) {
        throw new Error("Document creation failed!")
      }

      // Refresh payloads
      await _this.getPayloads(_this.state.project)

      // Disabled spining loading
      _this.switchSpinner(true)

      // if previous version it's exist
      if (_this.state.previousVersion._id) {
        _this.archivePayload()
      }

      NotificationManager.success("Disclosure Created!", "Success", 900)
      _this.resetValues()
    } catch (error) {
      _this.switchSpinner(true)
      console.error(error)
      NotificationManager.error(error.message, "Error")
    }
  }

  async uppyHandler() {
    return new Promise((resolve, reject) => {
      try {
        // Start to upload files via uppy
        uppy
          .upload()
          .then(async result => {
            // console.info("Successful uploads:", result.successful)
            try {
              // Upload failed due to no file being selected.
              if (result.successful.length <= 0 && result.failed.length <= 0) {
                return reject(new Error("File is required"))
                //throw new Error('File is required')
              } else if (result.failed.length > 0) {
                // Upload failed (for some other reason)

                // Error updload some file
                return reject(new Error("Fail to upload Some Files"))

                //throw new Error('Fail to upload Some Files')
              }

              _this.getFileData(result.successful)
            } catch (error) {
              throw error
            }
            resolve(true)
          })
          .catch(error => {
            return reject(new Error("File is required"))
          })
      } catch (error) {
        return reject(error)
      }
    })
  }

  getFileData(files) {
    const fileData = []

    if (!files.length) {
      return
    }

    // Push only the first file reference
    // into payload meta property ( ignoring thumbnail )
    const value = files[0]
    if (value.extension) {
      fileData.push(value.extension)
    } else {
      fileData.push("unknow")
    }

    // Push all files name references
    // to payload meta property
    //files.map(value => {
    //  if (value.extension) {
    //    fileData.push(value.extension)
    //  } else {
    //    fileData.push("unknow")
    //  }
    //})

    _this.setState({
      fileData: fileData,
    })
  }

  // Does this clear the state? Or reset it to a previous value?
  // ANSWER: This function clears the form fields in the modal And closes it
  resetValues() {
    _this.setState(prevState => ({
      ...prevState,
      formValues: {
        ...prevState,
        title: "",
        fileData: [],
      },
      filePreview: "",
      encrypt: 'encrypt'
    }))

    uppy && uppy.reset()
    _this.props.hideCurrentModal()
  }

  // What does this function do?
  /* ASNWER: This function handles the activation of the spinner
             we see on loading times
  */
  //Enable / Disable loading spinner
  switchSpinner(value) {
    _this.setState({
      loaded: value,
    })
  }

  // Create a local instance of a new metadata model.
  createMetadataModel() {
    const metadata = {
      schemaVersion: 1,
      projectId: _this.props.project._id,
      userIdUpload: getUser()._id,
      projectId: _this.props.project._id,
      title: _this.state.formValues.title,
      isNDA: _this.state.formValues.isNDA,
      owner: getUser()._id,
      meta: {
        fileData: _this.state.fileData,
        filePreview: "",
        emailOwner: getUser().email,
      },
    }
    const { previousVersion } = _this.state
    if (previousVersion && previousVersion._id) {
      metadata.previousVersion = previousVersion._id
    }
    return metadata
  }

  // Mark payload as archived
  async archivePayload() {
    try {
      const payload = _this.state.previousVersion
      if (payload.isArchived) return

      const toUpdate = {
        _id: payload._id,
        isArchived: !payload.isArchived,
      }

      const updateResult = await updatePayload(toUpdate)
      if (!updateResult || !updateResult.metadata) {
        throw new Error("Error trying to archive document")
      }

      const payloads = _this.props.payloads

      // update payloads array  , property isArchived
      payloads.map((val, i) => {
        if (val._id === payload._id) {
          payloads[i].isArchived = toUpdate.isArchived
        }
      })

      _this.props.setPayloads(payloads)
    } catch (error) {
      console.warn(error)
      NotificationManager.error("Error trying to archive document", "Error")
    }
  }
  changeEncrypt(value){
      _this.setState({
        encrypt : value
      })
  }
}

NewPayload.propTypes = {
  addPayloads: PropTypes.func,
  project: PropTypes.object,
  previousVersion: PropTypes.object,
}
