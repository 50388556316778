/* eslint-disable */
/**
 * This modal renders the form
 * to share payloads
 */
 import React from "react"
 import PropTypes from "prop-types"
 import { Button, Box, Content } from "adminlte-2-react"
 import { burnProjectToken } from "../../../services/projects"
 import { burnUserToken } from "../../../services/users"

 let _this
 
 export class Burn extends React.Component {
   constructor(props) {
     super(props)
     _this = this
     this.state = {
       txid: "",
       loaded: true,
       errMsg: ''
     }
   }
 
   render() {
     const { txid , loaded, errMsg} = _this.state
     const { ticker } = _this.props.selectedToken
     return (
       <Content
         className="text-center"
         modal
         modalCloseButton={true}
         onHide={_this.hideModal}
         show={true}
         modalSize="lg"
         /* Modal Header */
         title={<React.Fragment><div className="tx-time-content">
         <p>Burn {ticker}.</p>
       </div></React.Fragment>}
         modalFooter={
           <React.Fragment>
             <Box className="tx-footer-box " loaded={loaded}>
               <div className="tx-footer">
                 <Button
                   type="primary"
                   text={txid ? 'Close' : 'Cancel'}
                   onClick={_this.hideModal}
                 />
               { !txid && <Button
                   type="primary"
                   text="Burn"
                   onClick={_this.burn}
                 />}
               </div>
 
             </Box>
           </React.Fragment>
         }
       >
     
         {(!txid && loaded ) && <div className='text-center'><p>Are you sure you want to burn the token ?</p></div>}
         {errMsg && <div className='text-center'><p className='err-txt-msg'>{errMsg}</p></div>}
         {txid && <div className='text-center'> <p>The token has been burned.</p> <span>TXID: <a href={`https://token.fullstack.cash/transactions/?txid=${txid}`} target="_blank">{txid}</a></span> </div>
         
}
       </Content>
     )
   }
   componentDidMount() { }
 
 
   async burn() {
     try {
       _this.setState({
         loaded: false,
         errMsg:''
       })
 
       const { selectedToken , selectedProject , currentView } = _this.props
       let result

       if(currentView === 'project tokens'){
        result = await burnProjectToken(selectedProject._id , selectedToken.tokenId)

       }else if(currentView === 'my tokens'){
        result  = await burnUserToken(selectedToken.tokenId)

       }
       if (!result) {
         throw new Error("Error")
       }
       _this.setState({
         txid: result,
         loaded: true,
       })
     } catch (error) {
       _this.setState({
         errMsg: error.response ? error.response.data : error.message,
         loaded: true,
       })
     }
   }
   hideModal() {
    _this.refreshTokens()
    _this.props.hideCurrentModal()
  }

   refreshTokens(){
    try {
      const { txid } = _this.state
      if(!txid) return
      setTimeout(() => {
        const elem = document.getElementById('refresh-mytokens')
        elem.click()
      }, 2000);
    } catch (error) {
      console.error(error.message)
    }
  }

 }
 
 
 
 Burn.propTypes = {
   selectedToken: PropTypes.object,

 }
 