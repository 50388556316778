/* eslint-disable */
/**
 * This component is in charge of rendering a modal
 * with the form to add new projects
 */
import React from "react"
import PropTypes from "prop-types"
import { newProject } from "../../../services/projects"
import { getUserById } from "../../../services/users"
import { getUser, setUser } from "../../../services/auth"

import { NotificationManager } from "react-notifications"

import { NotificationContainer } from "react-notifications"

import { Content, Button, Inputs, Box } from "adminlte-2-react"

const { Text } = Inputs
let _this
export class NewProject extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      show: true,
      projectLabel: "",
      loaded: true,
      created: false,
    }
  }

  modalFooter = (
    <React.Fragment>
      <Button text="Close" pullLeft onClick={this.hideModal} />
      <Button type="primary" text="Submit" onClick={this.submitData} />
    </React.Fragment>
  )

  render() {
    const { currentModal } = _this.props
    return (
      <>
        <Content
          className="disclosure-form-container"
          title="Add New Project"
          modal
          // eslint-disable-next-line
          modalCloseButton={true}
          modalCloseButton={false}
          modalFooter={this.modalFooter}
          show={currentModal === "new project"}
        >
          <Box loaded={_this.state.loaded}>
            <Text
              id="project-label"
              name="projectLabel"
              placeholder="Enter Project Label"
              label="TITLE"
              labelPosition="above"
              onChange={this.handleUpdate}
            />
          </Box>
        </Content>
        <NotificationContainer />
      </>
    )
  }
  handleUpdate(event) {
    _this.setState({
      [event.target.name]: event.target.value,
    })
  }
  componentDidUpdate() {
    if (_this.state.show !== _this.props.showModal) {
      _this.setState({
        show: _this.props.showModal,
      })
    }
  }

  async submitData() {
    const isValidForm = _this.validateForm()

    if (!isValidForm) {
      NotificationManager.error("Title is required", "Error")
      return
    }

    _this.handleLoaded(false) // Enable loader
    // Project model
    const project = {
      schemaVersion: 1,
      projectLabel: _this.state.projectLabel,
    }
    // Fetch to create new project
    const result = await newProject(project)
    if (result.success) {
      _this.setState({
        created: true,
      })
      // NotificationManager.success("Project Created", "Success")
      // _this.props.getProjects()
    } else {
      NotificationManager.error("Error", "Error")
    }
    // Update users projects array
    const user = getUser()
    const userResult = await getUserById(user._id)
    console.log("user", user)
    // Set user to session storage with updated projects
    setUser({
      _id: userResult.user._id,
      email: user.email,
      jwt: user.jwt,
      projects: userResult.user.projects,
    })
    const myProjects = await _this.props.getProjects()
    _this.handleLoaded(true) // Disabled loader

    _this.props.handleProject(false)
    _this.props.navigateToProject(myProjects.length)
    _this.props.triggerNotification("Project Created", "success")
  }
  handleLoaded(value) {
    _this.setState({
      loaded: value,
    })
  }
  hideModal() {
    _this.props.handleProject(true)
  }

  validateForm() {
    let isOk = true
    if (!_this.state.projectLabel) isOk = false

    return isOk
  }
}
NewProject.propTypes = {
  handleProject: PropTypes.func,
  getProjects: PropTypes.func,
  navigateToProject: PropTypes.func,
  triggerNotification: PropTypes.func,
}
