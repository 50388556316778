/* eslint-disable */
/**
 * This modal renders the form
 * to share payloads
 */
 import React from "react"
 import PropTypes from "prop-types"
 import { Button, Box, Content } from "adminlte-2-react"
 import { redeemToken } from "../../../services/token"
 
 let _this
 const TokenDisplayUrl = 'https://token.fullstack.cash/'
 
 export class Redeem extends React.Component {
   constructor(props) {
     super(props)
     _this = this
     this.state = {
       txid: "",
       loaded: true,
       errMsg: ''
     }
   }
 
   render() {
     const { txid , loaded, errMsg} = _this.state
     const { ticker } = _this.props.selectedToken
     return (
       <Content
         className="text-center"
         modal
         modalCloseButton={true}
         onHide={_this.hideModal}
         show={true}
         modalSize="lg"
         /* Modal Header */
         title={<React.Fragment><div className="tx-time-content">
         <p>Redeem {ticker}.</p>
       </div></React.Fragment>}
         modalFooter={
           <React.Fragment>
             <Box className="tx-footer-box " loaded={loaded}>
               <div className="tx-footer">
                 <Button
                   type="primary"
                   text={txid ? 'Close' : 'Cancel'}
                   onClick={_this.hideModal}
                 />
               { !txid && <Button
                   type="primary"
                   text="Redeem"
                   onClick={_this.redeem}
                 />}
               </div>
 
             </Box>
           </React.Fragment>
         }
       >
     
         {!txid && <div className='text-center'><p>Are you sure you want to redeem the token ?</p></div>}
         {errMsg && <div className='text-center'><p className='err-txt-msg'>{errMsg}</p></div>}
         {txid && <div className='text-center'> <p>The token has been burned and redeemed for download access to the Payload it represents. You can now navigate to the Download view, which will allow you to download the Payload. The link below is proof that the token has been redeemed.</p> <span>TXID: <a href={`https://token.fullstack.cash/transactions/?txid=${txid}`} target="_blank">{txid}</a></span> </div>
         
}
       </Content>
     )
   }
   componentDidMount() { }
 
 
   async redeem() {
     try {
       _this.setState({
         loaded: false,
         errMsg:''
       })
 
       const { selectedToken } = _this.props
       //// tokenize request
       const result = await redeemToken(selectedToken.tokenId)
       if (!result) {
         throw new Error("Error")
       }
       _this.setState({
         txid: result,
         loaded: true,
       })
     } catch (error) {
       console.warn(error.response.data)

       _this.setState({
         errMsg: error.response ? error.response.data : error.message,
         loaded: true,
       })
     }
   }
   hideModal() {
    _this.refreshTokens()
    _this.props.hideCurrentModal()
  }

   refreshTokens(){
    try {
      const { txid } = _this.state
      if(!txid) return
      setTimeout(() => {
        const elem = document.getElementById('refresh-mytokens')
        elem.click()
      }, 2000);
    } catch (error) {
      console.error(error.message)
    }
  }

 }
 
 
 
 Redeem.propTypes = {
   selectedToken: PropTypes.object,

 }
 