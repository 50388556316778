/* eslint-disable */
/**
 * This is the component in charge of rendering a modal
 * with the form to add new payloads and new
 * existent payload versions
 */
import React from "react"


import { NotificationManager } from "react-notifications"
// Uppy imports
const Uppy = require("@uppy/core")
const Tus = require("@uppy/tus")
const { Dashboard } = require("@uppy/react")
// const DragDrop = require("@uppy/drag-drop")
// const Webcam = require("@uppy/webcam")
// const GoogleDrive = require("@uppy/google-drive")
// const Dropbox = require("@uppy/dropbox")

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "@uppy/webcam/dist/style.css"

import { Content, Button, Box } from "adminlte-2-react"
import { changeIcon , getPayloadIcon } from "../../../services/metadatas"

const SERVER = process.env.GATSBY_API_URL

//instantiate uppy
const uppy = Uppy({
	meta: { test: "avatar"  },
	allowMultipleUploads: true,
	debug: false,
	restrictions: {
		maxFileSize: null,
		maxNumberOfFiles: 2,
		minNumberOfFiles: 1,
		allowedFileTypes: ['image/*'],//type of files allowed to load
	},
	onBeforeUpload: files => {
		const updatedFiles = Object.assign({}, files)
		Object.keys(updatedFiles).forEach(fileId => {
			const indexName = fileId.lastIndexOf("/")
			const fileName = fileId.substring(indexName, fileId.length)
			uppy.setFileMeta(fileId, { fileNameToEncrypt: fileName , resize: 1500 })
		})
		return updatedFiles
	},
})
// Adding plugins
uppy.use(Tus, {
	endpoint: `${SERVER}/files`,
	locale: {
		strings: {
			browse: "выберите ;-)",
		},
	},
})

// uppy.use(Webcam)
// uppy.use(GoogleDrive, { companionUrl: 'https://companion.uppy.io' })
// uppy.use(Dropbox, { companionUrl: 'https://companion.uppy.io' })

uppy.on("complete", result => {
	//
})

let _this

export class ChangeIconModal extends React.Component {
	constructor(props) {
		super(props)

		_this = this

		this.state = {
			show: false,
			loaded: true,
			fileData: [],
			filePreview: "",
			thumnailAdded: false
		}

		this.uppy = uppy
	}

	render() {
		const { currentModal } = _this.props

		return (
			<>
				<Content
					className="disclosure-form-container"
					title="Change Icon"
					modal
					// eslint-disable-next-line
					modalCloseButton={true}
					modalCloseButton={true}
					onHide={_this.hideModal}
					/* modalFooter={this.modalFooter} */
					show={currentModal === "change payload icon"}
					modalFooter={
						<React.Fragment>
							<Box
								className="disclosure-form-box-loader"
								loaded={_this.state.loaded}
							>
								<Button text="Close" pullLeft onClick={_this.resetValues} />
								<Button
									type="primary"
									text="Submit"
									onClick={this.submitData}
								/>
							</Box>
						</React.Fragment>
					}
				>
					<Dashboard
						uppy={uppy}
						plugins={["Tus", "Webcam", "Dropbox", "GoogleDrive"]}
						thumbnailWidth={512}
					/>
				</Content>
			</>
		)
	}

	handleUppyThumbnail() {
		this.uppy.on("thumbnail:generated", async (file, preview) => {
			try {
				if (!_this.state.thumnailAdded) {
					// Get thumbnail blob
					let blob = await fetch(preview).then(r => r.blob())
					_this.setState({
						filePreview: blob,
					})


					const files = uppy.getFiles()
					// console.log('files', files)
					const originalFile = files[0]
					const thumbnail = files[1]

					uppy.removeFile(originalFile.id)

					
					// Add thumbnail file to uppy
					uppy.addFile({
						name: `thumbnail-${file.name}`, // file name
						type: file.data.type, // file type
						data: blob, // file blob
					})

					_this.setState({ thumnailAdded : true })

				}

				// Convert file to base64 format (Example)
				/*       try {
								var reader = new FileReader()
								reader.onload = function(e) {
									console.log("reader.result")
			  
									_this.setState({
										filePreview: reader.result,
									})
								}
								reader.readAsDataURL(file.data)
							} catch (error) {
								console.error(error)
							} */
			} catch (error) {
				console.error(error)
			}
		})
	}
	async componentDidMount() {
		_this.handleUppyThumbnail()

		// Remove thumbnail when the main file is removed
		uppy.on("file-removed", (file, reason) => {
			uppy.reset()
			_this.setState({
				filePreview: "",
				thumnailAdded: false
			})
		})
	}


	// The text of the uppy dashboard comes from the library,
	// to change  it i had to create a function and use JS
	changeUppyDashboardText() {
		try {
			const newText = "Drop icon here, or "

			const uppyTextElement = document.getElementsByClassName(
				"uppy-Dashboard-AddFiles-title"
			)
			if (uppyTextElement[0]) {
				const childs = uppyTextElement[0].childNodes
				const textElement = childs[0]
				textElement.nodeValue = newText
			}
		} catch (error) {
			console.warn(error)
		}
	}
	componentDidUpdate() {

	}

	componentWillUnmount() {
		// Reset uppy default state
		this.uppy && this.uppy.reset()
	}

	// Click handler for the 'Submit to Blockchain' button.
	async submitData() {
		try {
			const { selectedPayload  } = _this.props
			_this.switchSpinner(false)

			// Array of encryption files names
			// This is sent to the backend to verify if it exist.
			// Tracks the server-side file names for referencing them after the upload
			// completes.
			let iconFileName
			// Get file name from uppy state, and push it into the array.
			const uppyState = uppy.getState()
			// console.log(
			//   `uppyState.files: ${JSON.stringify(uppyState.files, null, 2)}`
			// )
			Object.keys(uppyState.files).forEach(fileId => {
				//const indexName = fileId.lastIndexOf("/")
				//const fileName = fileId.substring(indexName, fileId.length)

				// console.log(`fileId: ${JSON.stringify(fileId,null,2)}`)
				// debugger

				// Add the uploaded file name to the array.
				// the first object contains the original file name
				// the second object contains the thumbnail name
				iconFileName = uppyState.files[fileId].name

			})

			// console.log(`encryptionFileName: ${encryptionFileName}`)
			// console.log(`thumbnailFileName: ${thumbnailFileName}`)

			//uppy error handler
			await _this.uppyHandler()
			// console.log('Icon File Name: ', iconFileName)
			await changeIcon(selectedPayload._id, iconFileName)

			await _this.updatePayload()

			NotificationManager.success("Disclosure Created!", "Success", 900)
			_this.resetValues()
		} catch (error) {
			_this.switchSpinner(true)
			NotificationManager.error(error.message, "Error")
		}
	}
	// update payload in redux store
    async updatePayload(){
		try {
			const { selectedPayload } = _this.props
			const payloads = _this.props.payloads
			for(let i = 0 ; payloads.length ; i++){
				const payload =payloads[i]
				if(payload._id === selectedPayload._id){
					const thumbnailIpfsUrl = await getPayloadIcon(selectedPayload._id)
					payload.meta.thumbnailIpfsUrl = thumbnailIpfsUrl
				}
			}
			_this.props.setPayloads(payloads)
		} catch (error) {
			console.log(error)
		}
	}
	async uppyHandler() {
		return new Promise((resolve, reject) => {
			try {
				// Start to upload files via uppy
				uppy
					.upload()
					.then(async result => {
						// console.info("Successful uploads:", result.successful)
						try {
							// Upload failed due to no file being selected.
							if (result.successful.length <= 0 && result.failed.length <= 0) {
								return reject(new Error("File is required"))
								//throw new Error('File is required')
							} else if (result.failed.length > 0) {
								// Upload failed (for some other reason)

								// Error updload some file
								return reject(new Error("Fail to upload Some Files"))

								//throw new Error('Fail to upload Some Files')
							}

							_this.getFileData(result.successful)
						} catch (error) {
							throw error
						}
						resolve(true)
					})
					.catch(error => {
						return reject(new Error("File is required"))
					})
			} catch (error) {
				return reject(error)
			}
		})
	}

	getFileData(files) {
		const fileData = []

		if (!files.length) {
			return
		}

		// Push only the first file reference
		// into payload meta property ( ignoring thumbnail )
		const value = files[0]
		if (value.extension) {
			fileData.push(value.extension)
		} else {
			fileData.push("unknow")
		}

		// Push all files name references
		// to payload meta property
		//files.map(value => {
		//  if (value.extension) {
		//    fileData.push(value.extension)
		//  } else {
		//    fileData.push("unknow")
		//  }
		//})

		_this.setState({
			fileData: fileData,
		})
	}

	// Does this clear the state? Or reset it to a previous value?
	// ANSWER: This function clears the form fields in the modal And closes it
	resetValues() {
		_this.setState(prevState => ({
			...prevState,
			formValues: {
				...prevState,
				fileData: [],
			},
			filePreview: "",
		}))

		uppy && uppy.reset()
		_this.props.hideCurrentModal()
	}


	//Enable / Disable loading spinner
	switchSpinner(value) {
		_this.setState({
			loaded: value,
		})
	}

	hideModal() {
		_this.props.hideCurrentModal()
	}


}

ChangeIconModal.propTypes = {

}
