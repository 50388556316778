/* eslint-disable */
/**
 * This component is in charge of rendering a modal
 * with the user addresses
 */
import React from "react"
//import PropTypes from "prop-types"
import { getUserAddrs } from "../../../services/users"
import { getUser } from "../../../services/auth"

import { NotificationContainer } from "react-notifications"

import { Content, Box } from "adminlte-2-react"
import { QRCodeSVG } from 'qrcode.react';
import copy from 'copy-to-clipboard'

// const QRCode = require('qrcode.react')

let _this
export class DepositAddress extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      show: true,
      loaded: true,
      errMsg: "",
      addresses: '',
      addr: '',
      selectedAddr: 'SLP',
      copySuccess: false
    }
  }


  render() {
    const { currentModal } = _this.props
    const { errMsg, loaded, addresses, addr, selectedAddr, copySuccess } = _this.state

    return (
      <>
        <Content
          className="disclosure-form-container "
          title={`Deposit Addresses ( ${selectedAddr} )`}
          modal
          // eslint-disable-next-line
          modalCloseButton={true}
          modalCloseButton={true}
          onHide={_this.hideModal}
          /* modalFooter={this.modalFooter} */
          show={currentModal === "deposit address"}
        >
          <Box loaded={loaded} className="border-none text-center">
            {addresses && (
              <>
                {copySuccess &&
                  <div className='copied-message mt-1'>
                    Copied!
                  </div>}
                <QRCodeSVG
                  className='qr-code'
                  value={addr}
                  size={350}
                  includeMargin
                  fgColor='#333'
                  onClick={_this.handleCopyAddres}

                />
                <p><span className='text-main-color'><b>{selectedAddr} Address :</b> </span> {addr} </p>
                {selectedAddr === 'BCH' && <Box className='text-center'>
                  <p className='warn-txt-msg'>
                  <p><b>WARNING</b></p>
                  <p><b>Careful! Not all Bitcoin Cash wallets are token-aware. If you send this token to a wallet that is not token-aware, it could be burned. It's best practice to only send tokens to 'simpleledger' addresses.</b></p>
                </p> </Box>
                }
                <label className='switch-address' htmlFor='address-checkbox'>
                  <input
                    id='address-checkbox'
                    type='checkbox'
                    onChange={_this.handleChangeAddr}
                  />
                  <span className='slider round' />
                </label>
              </>
            )}
            {errMsg && <p>{errMsg}</p>}
          </Box>
        </Content>
        <NotificationContainer />
      </>
    )
  }
  handleUpdate(event) {
    _this.setState({
      [event.target.name]: event.target.value,
    })
  }
  async componentDidMount() {
    const user = getUser()
    // fetch user addresses if it does not exist in redux store
    if (_this.props.userData.addresses && user._id === _this.props.userData._id) {
      _this.setState({
        addresses: _this.props.userData.addresses,
        loaded: true,
        addr: _this.props.userData.addresses.slpAddress
      })
    } else {
      await _this.getAddresses()
    }
  }
  componentDidUpdate() {
    if (_this.state.show !== _this.props.showModal) {
      _this.setState({
        show: _this.props.showModal,
      })
    }
  }

  async getAddresses() {
    // Get Addresses
    try {
      _this.handleLoaded(false)

      const user = getUser()
      const result = await getUserAddrs(user._id)
      if (!result.success) {
        _this.setState({
          errMsg: 'Addresses cant be found!'
        })
        _this.handleLoaded(true)

        return
      }


      _this.setState({
        addresses: result.addresses,
        addr: result.addresses.slpAddress,
        loaded: true
      })

      const userD = _this.props.userData
      userD.addresses = result.addresses
      userD._id = user._id

      _this.props.setUserData(userD)
    } catch (error) {
      console.warn(error)
      _this.handleLoaded(true)

    }
  }

  handleChangeAddr() {
    const checkbox = document.getElementById('address-checkbox')
    const { cashAddress, slpAddress } = _this.state.addresses

    let addr
    let selectedAddr
    if (!checkbox.checked) {
      addr = slpAddress
      selectedAddr = 'SLP'
    } else {
      addr = cashAddress
      selectedAddr = 'BCH'

    }
    _this.setState({
      addr,
      selectedAddr
    })
  }
  handleLoaded(value) {
    _this.setState({
      loaded: value,
    })
  }
  hideModal() {
    _this.props.hideCurrentModal()
  }
  handleCopyAddres() {
    const address = _this.state.addr
    copy(address)
    _this.setState({ copySuccess: true })

    setTimeout(function () {
      _this.setState({ copySuccess: false })
    }, 1500)
  }
}
DepositAddress.propTypes = {
}
