import "isomorphic-fetch"
import { getUser } from "./auth"

const SERVER = process.env.GATSBY_API_URL

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== "undefined"

// get all projects
export const getShadowUsers = async () => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/shadow-users`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
// get  project by id
export const getShadowUserById = async id => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const resp = await fetch(`${SERVER}/shadow-users/${id}`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const newShadowUser = async shadowUser => {
  const token = getUser().jwt ? getUser().jwt : ""

  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        shadowUser: shadowUser,
      }),
    }
    const data = await fetch(`${SERVER}/shadow-users/`, options)
    const resp = await data.json()
    console.log("new shadow user resp", resp)

    return resp
  } catch (err) {
    // If something goes wrong with auth, return false.
    return false
  }
}

export const shadowUsersUpdateShared = async (
  email,
  payloadId,
  ignoreEmailCheck
) => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ""

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        share: {
          email: email,
          metadataId: payloadId,
          ignoreEmailCheck,
        },
      }),
    }
    const resp = await fetch(`${SERVER}/shadow-users/share`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}
