/**
 *
 * This component generates and renders all the payload cards
 * associated to the selected project
 */
import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
// eslint-disable-next-line
import AdminLTE, { Row, Col, Box } from "adminlte-2-react"
//import Jdenticon from "react-jdenticon"

import jpg from "../../images/thumbnails/jpg-svgrepo-com.svg"
import doc from "../../images/thumbnails/word.svg"
import pdf from "../../images/thumbnails/pdf.svg"
import png from "../../images/thumbnails/png.svg"
import rar from "../../images/thumbnails/rar.svg"
import txt from "../../images/thumbnails/txt.svg"
import zip from "../../images/thumbnails/zip.svg"
import unknow from "../../images/thumbnails/unknow.svg"

// Representative images for file extensions
const extensionsUrl = {
  pdf: pdf,
  doc: doc,
  docx: doc,
  png: png,
  jpg: jpg,
  jpeg: jpg,
  rar: rar,
  txt: txt,
  zip: zip,
  unknow: unknow,
}

let _this
export class PayloadCards extends Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      payloadList: [],
    }
  }

  render() {
    const { payloadList } = _this.state
    return (
      <Row>
        {!_this.props.hideAddCard && (
          <Col
            xs={12}
            md={4}
            onClick={() => _this.props.showModal("new payload")}
          >
            <Box padding="true" className="box-new">
              <Row>
                <Col xs={12}>
                  <FontAwesomeIcon size="2x" icon={["fas", "plus"]} />
                </Col>
                <Col xs={12}>
                  <span>Add New Document</span>
                </Col>
              </Row>
            </Box>
          </Col>
        )}

        {payloadList &&
          payloadList.map((val, i) => {
            let show = false
            if (!val.isThumbnail) {
              if (_this.props.showArchived) {
                if (val.isArchived) {
                  show = true
                }
              } else {
                if (!val.isArchived) {
                  show = true
                }
              }
            }
            // Validate Archiveds Section
            return (
              show && (
                <Col
                  key={i}
                  xs={12}
                  md={4}
                  onClick={() => _this.props.getPayloadInfo(val)}
                >
                  
                  <Box padding="true" className="box-created">
                    <Row>
                      <Col xs={12} className="body-disclosure">
                        <span>{val.title ? val.title : ""}</span>
                      </Col>
                      <Col xs={12} className="body-icon-disclosure">
                        {val.meta.filePreview && val.meta.thumbnailIpfsUrl ? (
                          <div className="thumbnail-container">
                            <img
                              // temporarily set to use the default
                              // icons until the thumbnails created in this task -->
                              // https://trello.com/c/Bo2Udccy can be retrieved
                              src={val.meta.thumbnailIpfsUrl} /* src={val.meta.filePreview} */
                              className="payload-thumbnail"
                              alt="thumbnail"
                            />
                          </div>
                        ) : (
                          <div className="thumbnail-container">
                            <img
                              key={i}
                              alt="thumbnail"
                              className="payload-thumbnail"
                              src={
                                extensionsUrl[val.meta.fileData[0]] || unknow
                              }
                            />
                            {/*   <Jdenticon size="100" value={val._id + val.title} /> */}
                          </div>
                        )}
                      </Col>
                      <Col xs={12} className="footer-disclosure">
                        <span>
                          {val.createdTimestamp
                            ? _this.parseTimestamp(val.createdTimestamp)
                            : ""}
                        </span>
                      </Col>
                    </Row>
                  </Box>
                </Col>
              )
            )
          })}
      </Row>
    )
  }
  componentDidMount() {
    console.log("Payload Card props..", _this.props)
  }
  componentDidUpdate() {
    if (_this.state.payloadList !== _this.props.payloadList) {
      _this.setState({
        payloadList: _this.props.payloadList,
      })

      // Force to update the View when the payload list is changed
      // This function prevent visual errors
      setTimeout(() => {
        this.forceUpdate()
      }, 700)
    }
  }
  parseTimestamp(timeStamp) {
    // Months array
    const months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    // Convert timestamp to milliseconds
    const date = new Date(timeStamp * 1000)

    // Year
    const year = date.getFullYear()

    // Month
    const month = months_arr[date.getMonth()]

    // Day
    const day = date.getDate()

    // Hours
    const hours = date.getHours()

    // Minutes
    const minutes = "0" + date.getMinutes()

    // Seconds
    const seconds = "0" + date.getSeconds()

    // Display date time in MM-dd-yyyy h:m:s format
    const _date =
      month +
      "-" +
      day +
      "-" +
      year +
      " " +
      hours +
      ":" +
      minutes.substr(-2) +
      ":" +
      seconds.substr(-2)

    // Display date time in MM-dd-yyyy  format
    // const _date = month + '-' + day + '-' + year;

    return _date
  }
  
}



PayloadCards.propTypes = {
  getPayloadInfo: PropTypes.func,
  showArchived: PropTypes.bool,
  hideAddCard: PropTypes.bool,
  payloadList: PropTypes.array,
}
