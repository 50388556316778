/*
  A simple login form.
*/
/* eslint-disable */
import React from "react"

import { NotificationManager } from "react-notifications"

import { Row, Button, Col, Inputs } from "adminlte-2-react"
import { getUser, setUser } from "../../services/auth"
import { updateUser } from "../../services/users"
import CircularProgress from "@material-ui/core/CircularProgress"
import "./update.css"

const { Text } = Inputs

let _this

class NavbarUser extends React.Component {
  constructor(props) {
    super(props)

    _this = this

    this.state = {
      email: getUser().email,
      password: "",
      loaded: true,
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="user-update-form">
          <Row>
            <Col xs={12} md={12} className="user-update-input">
              <Text
                id="email"
                name="email"
                placeholder="Enter New Email"
                label="Email"
                labelPosition="above"
                value={_this.state.email}
                onChange={this.handleUpdate}
              />
            </Col>
            <Col xs={12} md={12} className="user-update-button">
              <Button
                type="primary"
                text="Update Email Address"
                name="changeEmail"
                onClick={_this.changeEmail}
              ></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="user-update-input">
              <Text
                id="password"
                name="password"
                inputType="password"
                placeholder="Enter New Password"
                label="Password"
                labelPosition="above"
                value={_this.state.password}
                onChange={this.handleUpdate}
                onFocus={this.resetPassword}
              />
            </Col>
            <Col xs={12} md={12} className="user-update-button">
              <Button
                type="primary"
                text="Change Password"
                name="changePassword"
                onClick={_this.changePassword}
              ></Button>
            </Col>
          </Row>
          {!_this.state.loaded && <CircularProgress />}
        </div>
      </React.Fragment>
    )
  }
  componentDidMount() {
    console.log(getUser())
  }
  //
  resetEmail() {
    _this.setState({
      email: getUser().email,
    })
  }
  resetPassword() {
    _this.setState({
      password: "",
    })
  }
  async changeEmail() {
    try {
      // Object to update
      const user = {
        _id: getUser()._id,
      }

      // Validate inputs
      if (_this.state.email === getUser().email) {
        return
      } else {
        // Validate email
        const isEmail = _this.validateEmail(_this.state.email)
        if (!isEmail) throw new Error("Email: Must be Email Format")
        // Add email to user object
        user.email = _this.state.email
      }

      _this.setState({
        loaded: false
      })

      // Request update
      const isUpdated = await updateUser(user)
      if (!isUpdated) {
        throw new Error("Update Error")
      }

      // Set new Email to session storage
      if (user.email) {
        const userData = getUser()
        userData.email = user.email
        setUser(userData)
      }


      NotificationManager.success("Update Success", "Success", 900)
      _this.setState({
        loaded: true
      })
      _this.resetEmail()
      _this.resetPassword()

    } catch (error) {
      NotificationManager.error(error.message, "Error", 2000)
      _this.setState({
        email: getUser().email,
        loaded: true
      })
    }
  }
  async changePassword(){
    try {
      // Object to update
      const user = {
        _id: getUser()._id,
      }

      if (!_this.state.password) {
        throw new Error("Password can't be empty")
      }
      
      // Add password to user object
      user.password = _this.state.password

      _this.setState({
        loaded: false
      })

      const isUpdated = await updateUser(user)
      if (!isUpdated) {
        throw new Error("Update Error")
      }

      NotificationManager.success("Update Success", "Success", 900)
      _this.setState({
        loaded: true
      })
      _this.resetEmail()
      _this.resetPassword()
    } catch (error) {
      NotificationManager.error(error.message, "Error", 2000)
      _this.setState({
        email: getUser().email,
        loaded: true
      })
    }
  }

  handleUpdate(event) {
    _this.setState({
      [event.target.name]: event.target.value,
    })
  }
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }
}

export default NavbarUser

