
/* eslint-disable*/
import React from "react"


const changeCheckboxStyle = (color) => {
  const checkBoxArchived = document.getElementById("showArchivedProjects")
  checkBoxArchived.style.backgroundColor = color
}

const onClickFunc= (id , callback)=>{
  const checkBoxArchived = document.getElementById(id)
  const isChecked = checkBoxArchived.checked
  checkBoxArchived.checked = !isChecked
  if(callback) callback()
}
const ItemCheckBox = (props) => {
  const { id , onClick  , text , label} = props
  return (

    <li
      onClick={()=> onClickFunc(id, onClick)}
      onMouseOver={() => changeCheckboxStyle("white")}
      onFocus={() => changeCheckboxStyle("white")}

      onMouseLeave={() => changeCheckboxStyle("#b8c7ce")}
      onKeyDown={()=>{}}
    >
      <div className="form-group">
        <div className="archived-filter-menu">
          <input
            className="custom-checkbox-menu"
            aria-label={label}
            type="checkbox"
            id={id}
            onClick={()=> onClickFunc(id, onClick)}
            //defaultChecked={props.isChecked}
          />
          <span> {text}</span>
        </div>
      </div>
    </li>
  )
}

export default ItemCheckBox;
