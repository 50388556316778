import "isomorphic-fetch"

const SERVER = process.env.GATSBY_API_URL

export const contact = async ({
  email,
  msg,
  emailList,
  payloadTitle,
  ignoreEmailCheck,
  payloadId,
}) => {
  // Try to authenticate.
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        obj: {
          email: email,
          formMessage: msg,
          emailList: emailList,
          payloadTitle: payloadTitle,
          ignoreEmailCheck,
          payloadId,
        },
      }),
    }
    const data = await fetch(`${SERVER}/contact/email`, options)
    if (data.ok) {
      return data.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong with auth, return false.
    return false
  }
}
