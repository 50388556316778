/* eslint-disable */
/**
 * This component is in charge of rendering a modal
 * with the redeem token functionality
 */
 import React from "react"

 
 import { Content,  Box } from "adminlte-2-react"
 
 // const QRCode = require('qrcode.react')
 
 let _this
 export class TokenInfo extends React.Component {
   constructor(props) {
     super(props)
     _this = this
     this.state = {

     }
   }
 
 
   render() {
     const { currentModal } = _this.props
 
     return (
       <>
         <Content
           className="disclosure-form-container"
           title="Info"
           modal
           // eslint-disable-next-line
           modalCloseButton={true}
           modalCloseButton={true}
           onHide={_this.hideModal}
           /* modalFooter={this.modalFooter} */
           show={currentModal === "token info"}
         >
           <Box  className="border-none">
                <p>Info</p>
           </Box>
         </Content>
       </>
     )
   }

   async componentDidMount() {

   }

 

   hideModal() {
     _this.props.hideCurrentModal()
   }

 }
 TokenInfo.propTypes = {
 }
 