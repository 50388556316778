import 'isomorphic-fetch'
import { getUser } from './auth'

const SERVER = process.env.GATSBY_API_URL
const GATEWAY =process.env.IPFS_GATEWAY

const axios = require('axios').default

// Detect if the app is running in a browser.
export const isBrowser = () => typeof window !== 'undefined'

// Get all metadatas
export const getMetadatas = async () => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get all metadatas
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const resp = await fetch(`${SERVER}/metadatas`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
// Get  metadatas by id
export const getmetadataById = async metadata => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get  metadata by id
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const resp = await fetch(`${SERVER}/metadatas/${metadata._id}`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
// Create new metadata
export const newMetadata = async (metadata, fileNames) => {
  // Try to create new metadata
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        metadata: metadata,
        encryptionFileNames: fileNames
      })
    }
    const resp = await fetch(`${SERVER}/metadatas/`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// export metadata
export const uploadMetadata = async (metadata, projectId) => {
  // Try to create new metadata
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        metadata: metadata,
        projectId: projectId
      })
    }
    const resp = await fetch(`${SERVER}/metadatas/upload`, options)

    if (resp.ok) {
      return resp.json()
    } else {
      const msg = await resp.text()
      throw new Error(msg)
    }
  } catch (err) {
    // If something goes wrong , return false.
    throw err;

  }
}

//Update metadata
export const updateMetadata = async metadata => {
  console.log(metadata)
  // Try to update a  metadata
  const token = getUser().jwt ? getUser().jwt : ""
  try {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        metadata: metadata,
      }),
    }
    const resp = await fetch(`${SERVER}/metadatas/${metadata._id}`, options)
    const myResp = await resp.json()

    return myResp
  } catch (err) {
    // If something goes wrong , return false.
    return false
  }
}

// Get OP_RETURN
export const getOpReturn = async id => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get  metadata by id
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const resp = await fetch(`${SERVER}/metadatas/opreturn/${id}`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

// Get  metadatas from ipfs
export const getMetadataFromIPFS = async metadata => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get  metadata by id
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const resp = await fetch(`${SERVER}/metadatas/fromipfs/${metadata._id}`, options)
    if (resp.ok) {
      return resp.json()
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const changeIcon = async (payloadId ,fileName) => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/metadatas/icon`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        payloadId,
        fileName,
      }
    }
    const result = await axios(options)
    const res = result.data //await data.json()
    return res
  } catch (e) {
    throw e
  }
}

export const getPayloadIcon = async (payloadId ) => {
  const token = getUser().jwt ? getUser().jwt : ""

  //try to get all projects
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/metadatas/icon/${payloadId}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }
    const result = await axios(options)
    const res = result.data //await data.json()
    const url = `${GATEWAY}${res.result}`
    return url
  } catch (e) {
    throw e
  }
}