/* eslint-disable */
/**
 * This component renders a modal with the details of a
 * specific payload in the 'projects' view
 */
import React from "react"
import PropTypes from "prop-types"
import { downloadFiles, updatePayload } from "../../../services/payload"
import { updateMetadata } from "../../../services/metadatas"
import { contact } from "../../../services/contact"
import { getUser } from "../../../services/auth"
import { updateSharedProjects } from "../../../services/users"
import {
  shadowUsersUpdateShared,
  getShadowUsers,
  newShadowUser,
} from "../../../services/shadowUsers"

import { Content, Box, Button, Inputs } from "adminlte-2-react"

import { NotificationManager } from "react-notifications"

import { Share } from "./share"
import { ShareMethod } from "./share-method"
import { TokenizePayload } from "./tokenize"

import jpg from "../../../images/thumbnails/jpg-svgrepo-com.svg"
import doc from "../../../images/thumbnails/word.svg"
import pdf from "../../../images/thumbnails/pdf.svg"
import png from "../../../images/thumbnails/png.svg"
import rar from "../../../images/thumbnails/rar.svg"
import txt from "../../../images/thumbnails/txt.svg"
import zip from "../../../images/thumbnails/zip.svg"
import unknow from "../../../images/thumbnails/unknow.svg"
const { Text } = Inputs

// Representative images for file extensions
const extensionsUrl = {
  pdf: pdf,
  doc: doc,
  docx: doc,
  png: png,
  jpg: jpg,
  jpeg: jpg,
  rar: rar,
  txt: txt,
  zip: zip,
  unknow: unknow,
}

let _this

/*
  The variable 'activeCustomThumbnail' is a flag that
  allows us to show or hide the custom upload thumbnail button
  in this section of the modal
  Link to the trello task with the instructions --> https://trello.com/c/jkY7rSSr
*/
const activeCustomThumbnail = false

export class PayloadModal extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      show: false,
      loaded: true,
      payloads: [],
      selectedPayload: {},
      time: "",
      section: "display", // display || share || share method
      emailList: [],
      message: "",
      payloadFilesData: "",
      archivedCheckBox: false,
      emailFormData: "",
      emailCheck: true,
      loadedFilePreview: "",
      filePreviewErrorTxt: "",
      thumbnailName: "",
      thumbnailInFetch: false,
      user: getUser(),
      dataToShareByEmail: {},
    }
  }

  render() {
    const { currentModal, hideCurrentModal } = _this.props
    const { selectedPayload } = _this.state

    return (
      <>
        <Content
          className="disclosure-form-container "
          modal
          modalCloseButton={true}
          onHide={hideCurrentModal}
          show={currentModal === "payload details"}
          modalSize="lg"
          /* Modal Header */
          title={
            _this.state.section === "display" ? (
              <React.Fragment>
                <div className="tx-header">
                  <div className="tx-time-content">
                    <p className="tx-time">{this.state.time}</p>
                    <Box id="tx-time-ico" icon="fa-unlock"></Box>
                  </div>

                  <p className="tx-signature">Digital Signature : </p>

                  <p className="tx-id" onClick={this.openExplorer}>
                    Tx:
                    <u>
                      {this.state.selectedPayload &&
                        this.state.selectedPayload.jsonTxId
                        ? this.state.selectedPayload.jsonTxId
                        : ""}
                    </u>
                  </p>
                  <p className="ipfs-hash">
                    IPFS Hash:
                    <span>
                      {this.state.selectedPayload.ipfsFileRetrievalHash
                        ? this.state.selectedPayload.ipfsFileRetrievalHash
                        : ""}
                    </span>
                  </p>
                </div>
              </React.Fragment>
            ) : _this.state.section === "share" ? (
              <React.Fragment>
                <div className="tx-time-content">
                  <p>Share Document With Others.</p>
                </div>
              </React.Fragment>
            ) : <React.Fragment>
              <div className="tx-time-content">
                <p></p>
              </div>
            </React.Fragment>
          }
          /*Modal Footer*/
          modalFooter={
            _this.state.section === "display" ? (
              <React.Fragment>
                <Box className="tx-footer-box" loaded={this.state.loaded}>
                  <div className="payload-card-footer">
                    {/*      <div className="archived-filter">
                      <input
                        className="custom-checkbox"
                        checked={_this.state.selectedPayload.isArchived}
                        type="checkbox"
                        id="archived"
                        name="archived"
                        onChange={_this.archivePayload}
                      />
                      <span>Archive this payload?</span>
                    </div> */}


                    <div className="footer-box-buttons">
                      {<Button
                        type="primary"
                        text="Change Icon"
                        onClick={_this.changeIcon}
                      />
                      }
                      {
                        <Button
                          type="primary"
                          text={_this.state.selectedPayload.isArchived ? 'Unarchive' : 'Archive'}
                          onClick={_this.archivePayload}
                        />
                      }
                      {_this.props.currentView === "shared with me" && (
                        <Button
                          type="primary"
                          text="Start Chat"
                          onClick={_this.startChat}
                        />
                      )}
                      <Button
                        type="primary"
                        text="New Version"
                        onClick={this.newVersion}
                      />
                      {/* Only can share unarchived and own payloads */}
                      {!_this.state.selectedPayload.isArchived &&
                        _this.state.selectedPayload.owner ===
                        _this.state.user._id && (
                          <Button
                            type="primary"
                            text="Share"
                            onClick={this.changeSection}
                          />
                        )}
                      <Button
                        type="primary"
                        text="Proof"
                        onClick={this.proof}
                      />
                      <Button
                        type="primary"
                        text="View File"
                        onClick={this.showFile}
                      />
                      <Button
                        type="primary"
                        text="Download"
                        onClick={this.fetchFiles}
                      />
                      <Button
                        type="primary"
                        text="Tokenize"
                        onClick={() => this.changeSection("tokenize")}
                      />
                    </div>
                  </div>
                </Box>
              </React.Fragment>
            ) : _this.state.section === "share" ? (
              <React.Fragment>
                <Box className="tx-footer-box" loaded={this.state.loaded}>
                  <div className="tx-footer">
                    <div
                      className="button-container"
                      onClick={this.changeSection}
                    >
                      <Button type="primary" text="Back" />
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="button-container">
                        <Button
                          type="primary"
                          text="Share"
                          onClick={_this.share}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </React.Fragment>
            )
              : (
                <React.Fragment></React.Fragment>
              )
          }
        >
          {/*Modal Body*/}
          {_this.state.section === "display" && (
            <>
              <div className="tx-body">
                <h2>
                  {_this.state.selectedPayload.title
                    ? _this.state.selectedPayload.title
                    : ""}
                </h2>
              </div>
              <Box
                loaded={!_this.state.thumbnailInFetch}
                className="extensions-thumbnails"
              >
                {_this.state.payloadFilesData.length > 0 && (
                  <h4>Disclosure Content.</h4>
                )}
                {/*!_this.state.thumbnailInFetch &&
                  // temporarily set to use the default
                  // icons until the thumbnails created in this task -->
                  //  https://trello.com/c/Bo2Udccy can be retrieved
                  (false ? ( //_this.state.filePreview ?
                    <img
                      className=""
                      height="80"
                      src={_this.state.filePreview}
                    />
                  ) : (
                    _this.state.payloadFilesData &&
                    _this.state.payloadFilesData.map((val, i) => {
                      return (
                        <img
                          key={i}
                          className=""
                          height="80"
                          width="80"
                          src={extensionsUrl[val] || unknow}
                        />
                      )
                    })
                  ))*/}
                  
                {selectedPayload._id &&
                  <>
                    {selectedPayload.meta.filePreview && selectedPayload.meta.thumbnailIpfsUrl ? (
                      <img
                        // temporarily set to use the default
                        // icons until the thumbnails created in this task -->
                        // https://trello.com/c/Bo2Udccy can be retrieved
                        src={selectedPayload.meta.thumbnailIpfsUrl} /* src={val.meta.filePreview} */
                        className=""
                        alt="thumbnail"
                        width="200"
                      />
                    ) : (
                      <img
                        alt="thumbnail"
                        className=""
                        width="200"
                        src={
                          extensionsUrl[selectedPayload.meta.fileData[0]] || unknow
                        }
                      />

                    )}
                  </>
                }

              </Box>

              {/*
                The variable 'activeCustomThumbnail' is a flag that
                allows us to show or hide the custom upload thumbnail button
                in this section of the modal
                Link to the trello task with the instructions --> https://trello.com/c/jkY7rSSr
              */}
              {activeCustomThumbnail && (
                <div>
                  {_this.state.loadedFilePreview ? (
                    <div className="updateThumbnails-inputs">
                      <Button
                        type="primary"
                        icon="fa-times"
                        onClick={_this.resetThumbnailInput}
                      />
                      <Button
                        type="primary"
                        text="Update"
                        onClick={_this.updatePayloadThumbnail}
                      />
                    </div>
                  ) : (
                    <div className="loadThumbnails-inputs">
                      {/* this text inputs is hiden */}
                      <Text
                        id="thumbnail-input"
                        name="thumbnail-input"
                        labelPosition="none"
                        inputType="file"
                        onChange={_this.handleInputThumbnail}
                      />
                      <Button
                        type="primary"
                        text="Upload Custom Icon"
                        onClick={_this.handleFileBrowser}
                      />
                    </div>
                  )}
                </div>
              )}

              {_this.state.thumbnailName && (
                <p className="thumbnail-name">
                  Name: {_this.state.thumbnailName}
                </p>
              )}
              {_this.state.filePreviewErrorTxt && (
                <p className="text-red">{_this.state.filePreviewErrorTxt}</p>
              )}
            </>
          )}
          {_this.state.section === "share" && (
            <Share
              setEmailList={_this.setEmailList}
              setMessage={_this.setMessage}
              setEmailFormData={_this.setEmailFormData}
              payloads={_this.props.payloads}
              handleEmailCheck={_this.handleEmailCheck}
            />
          )}
          {_this.state.section === "share method" && (
            <ShareMethod
              selectedPayload={_this.state.selectedPayload}
              dataToShareByEmail={_this.state.dataToShareByEmail}
              onHide={_this.closeModal}
              {..._this.props}
            />
          )}
          {_this.state.section === "tokenize" && (
            <TokenizePayload
              selectedPayload={_this.state.selectedPayload}
              onHide={_this.closeModal}
              changeSection={_this.changeSection}
              {..._this.props}
            />
          )}
        </Content>
      </>
    )
  }

  handleFileBrowser() {
    const fileInput = document.getElementsByName("thumbnail-input")
    fileInput[0].click()
  }
  resetThumbnailInput() {
    document.getElementsByName("thumbnail-input").value = null
    const payload = _this.state.selectedPayload
    _this.setState({
      loadedFilePreview: null,
      filePreviewErrorTxt: "",
      thumbnailName: "",
      filePreview: payload.meta.filePreview,
    })
  }
  // update payload
  async updatePayloadThumbnail() {
    try {
      const payload = _this.state.selectedPayload
      const filePreview = _this.state.loadedFilePreview
      if (!filePreview) return

      _this.setState({
        thumbnailInFetch: true,
      })
      const meta = payload.meta
      meta.filePreview = filePreview
      const toUpdate = {
        _id: payload._id,
        meta,
      }
      const updateResult = await updatePayload(toUpdate)
      if (!updateResult || !updateResult.payload) {
        throw new Error("Error Udating Thumbnail")
      }

      const payloads = _this.props.payloads
      // update payloads array  , property isArchived
      payloads.map((val, i) => {
        if (val._id === payload._id) {
          payloads[i].meta.filePreview = filePreview
        }
      })

      _this.props.setPayloads(payloads)
      _this.setState({
        thumbnailInFetch: false,
      })
      _this.resetThumbnailInput()
    } catch (error) {
      NotificationManager.error("Error Udating Thumbnail", "Error")
      _this.setState({
        thumbnailInFetch: false,
      })
    }
  }
  handleInputThumbnail(event) {
    try {
      const name = event.target.name
      const file = event.target.files[0]
      if (!file) throw new Error("Thumbnail file not found!")

      _this.setState({
        thumbnailName: file.name,
      })

      if (!file.type.includes("image"))
        throw new Error("Thumbnail must be a image type!")

      // Get base64 file url
      var reader = new FileReader()
      reader.onload = function (e) {
        _this.setState({
          loadedFilePreview: reader.result,
          filePreviewErrorTxt: "",
          thumbnailName: file.name,
          filePreview: reader.result,
        })
      }
      reader.readAsDataURL(file)
    } catch (error) {
      _this.setState({
        filePreviewErrorTxt: error.message || "Error loading file!",
      })
    }
  }
  handleEmailCheck(emailCheck) {
    _this.setState({
      emailCheck,
    })
  }
  handleUpdate(event) {
    const name = event.target.name
    const value = event.target.value
    _this.setState(prevState => ({
      ...prevState,
      formValues: {
        ...prevState,
        [name]: value,
      },
    }))
  }
  // Close tx id modal
  closeModal() {
    if (_this.state.section !== "display") {
      setTimeout(() => {
        _this.changeSection()
      }, 200)
    }
    _this.props.hideCurrentModal()
  }

  // Update State
  async componentDidUpdate() {
    if (_this.props.selectedPayload !== _this.state.selectedPayload) {
      const payload = _this.props.selectedPayload

      console.log("Selected Payload", payload)

      let fileData = []
      let isArchived = false
      let filePreview = null

      if (payload) {
        fileData =
          payload.meta && payload.meta.fileData ? payload.meta.fileData : []
        isArchived = payload.isArchived

        // Verify if thumbnail exist
        if (payload.meta && payload.meta.filePreview) {
          filePreview = payload.meta.filePreview
        }
      }

      _this.setState({
        selectedPayload: payload,
        payloadFilesData: fileData,
        archivedCheckBox: isArchived,
        loadedFilePreview: null,
        filePreview,
      })
      setTimeout(() => {
        _this.getTime()
      }, 100)
    }

    setTimeout(() => {
      if (_this.state.show && _this.state.section !== "share") {
        try {
          const modal = document.getElementsByClassName("modal-dialog")
          modal[0].id = "modalTx"
        } catch (error) {
          console.error(error)
        }
      }
    }, 50)
  }
  // Parse timestamp unix to Date
  getTime() {
    let time
    try {
      const unixTime = _this.state.selectedPayload.createdTimestamp
      if (!unixTime) throw new Error()
      const createdTimestamp = new Date(unixTime * 1000)
      time = createdTimestamp.toString()
    } catch (error) {
      time = ""
    }
    _this.setState({
      time: time,
    })
  }

  openExplorer() {
    window.open(
      `https://explorer.bitcoin.com/bch/tx/${_this.state.selectedPayload.jsonTxId}`,
      "_blank"
    )
  }
  //Enable / Disable loading spinner
  switchSpinner(value) {
    _this.setState({
      loaded: value,
    })
  }
  async fetchFiles() {
    try {
      _this.switchSpinner(false)
      const result = await downloadFiles(_this.state.selectedPayload)
      if (!result) {
        throw new Error("Error Fetching files data")
      }
      const reader = result.body.getReader()
      await _this.startDownloadfiles(reader)
      //console.log('metadata',_this.state.metadata)
      await _this.logFileAccess("download")

      _this.switchSpinner(true)
      _this.props.hideCurrentModal()
    } catch (error) {
      _this.switchSpinner(true)
      NotificationManager.error("Download Error", "Error")
    }
  }

  // Set file data to blob type
  // Create html code to start file download
  async startDownloadfiles(reader) {
    try {
      if (!reader) {
        throw new Error("Error starting download")
      }

      let chunks = [] // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          break
        }

        chunks.push(value)
      }

      // Create html element
      // This element will contain the link
      // to start the download
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.style = "display: none"

      const fileName = _this.state.selectedPayload.title
        ? _this.state.selectedPayload.title
        : _this.state.selectedPayload._id

      const fileExt = ".zip"

      const blob = new Blob(chunks)
      const url = window.URL.createObjectURL(blob)

      a.href = url
      a.download = fileName + fileExt
      a.click()
      window.URL.revokeObjectURL(url)
      NotificationManager.success("Download Started", "Success", 2000)
    } catch (error) {
      throw error
    }
  }

  changeSection(section) {
    let sect
    if (typeof section == 'string') {
      sect = section
    } else {
      sect = _this.state.section === "display" ? "share" : "display"
    }

    _this.setState({
      section: sect,
    })
    console.log(`Change section from ${_this.state.section} , to ${sect}`)
  }

  async share() {
    const user = getUser()

    const emails = _this.state.emailList
    const msg = _this.state.message
    try {
      _this.switchSpinner(false)

      //console.log(emails)

      // Validate Message field
      if (!msg) {
        throw new Error("Please complete message field")
      }

      // Validate email field
      if (_this.state.emailFormData && !_this.validateEmailRepeat()) {
        const isEmail = _this.state.emailCheck
          ? _this.validateEmail(_this.state.emailFormData)
          : true

        if (!isEmail) {
          throw new Error("Email is invalid!")
        } else {
          emails.push(_this.state.emailFormData)
        }
      }
      // Validate form fields
      if (!msg && !emails.length) {
        throw new Error("Please complete form")
      }

      // Validate email list
      if (!emails.length) {
        throw new Error("Please add email")
      }

      const data = {
        email: user.email,
        msg: _this.state.message,
        emailList: emails,
        payloadTitle: _this.state.selectedPayload.title,
        ignoreEmailCheck: !_this.state.emailCheck,
        payloadId: _this.state.selectedPayload._id,
      }

      console.log(`Sending mail to :`, emails)

      const sharedWith = await _this.updateSharedP(emails)
      // Fetch contact endpoint
      /*    const result = await contact(data)

      if (!result) {
        throw new Error("Error")
      } */
      // Update sharedWith property
      await _this.updateSharedWith(sharedWith)

      // Update permissions
      await _this.updateSharedPermissions()

      // NotificationManager.success("Shared successfully!", "Success", 2000)

      _this.resetValues()
      _this.switchSpinner(true)

      _this.setState({
        dataToShareByEmail: data,
        section: "share method",
      })
      //_this.closeModal()
    } catch (error) {
      _this.switchSpinner(true)
      // NotificationManager.error(error.message, "Sharing Error")
    }
  }
  resetValues() {
    _this.setState({
      emailFormData: "",
      emailList: [],
      message: "",
    })
  }
  setEmailList(emails) {
    _this.setState({
      emailList: emails,
    })
  }

  setEmailFormData(email) {
    _this.setState({
      emailFormData: email,
    })
  }
  setMessage(msg) {
    _this.setState({
      message: msg,
    })
  }
  // update payload
  async archivePayload() {
    try {
      const payload = _this.state.selectedPayload
      const toUpdate = {
        _id: payload._id,
        isArchived: !payload.isArchived,
      }
      const updateResult = await updatePayload(toUpdate)

      if (!updateResult || !updateResult.metadata) {
        throw new Error("Error trying to archive document")
      }

      const payloads = _this.props.payloads

      // update payloads array  , property isArchived
      payloads.map((val, i) => {
        if (val._id === payload._id) {
          payloads[i].isArchived = toUpdate.isArchived
        }
      })

      _this.props.setPayloads(payloads)

      let output = toUpdate.isArchived
        ? "Document Archived!"
        : "Document Unarchived!"

      NotificationManager.success(output, "Success", 2000)
      _this.props.hideCurrentModal()
    } catch (error) {
      console.warn(error)
      NotificationManager.error("Error trying to archive document", "Error")
    }
  }

  validateEmailRepeat() {
    return _this.state.emailList.includes(_this.state.emailFormData)
  }
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }

  proof() {
    const originUrl = window.location.origin
    const payloadId = _this.state.selectedPayload._id
    const url = `${originUrl}/proof/?id=${payloadId}`
    window.open(url, "_blank")
  }

  async showFile() {
    const originUrl = window.location.origin
    const payloadId = _this.state.selectedPayload._id
    const url = `${originUrl}/showfiles/?id=${payloadId}`
    window.open(url, "_blank")
    await _this.logFileAccess("view")
  }

  // Update sharedWith payload property
  async updateSharedWith(sharedWith) {
    try {
      const payload = _this.state.selectedPayload
      const newArr = payload.sharedWith.concat(sharedWith)

      const toUpdate = {
        _id: payload._id,
        sharedWith: newArr,
      }

      // Updates the payload on the server
      const updateResult = await updatePayload(toUpdate)

      if (!updateResult) throw new Error("Error sharing project")

      // Update the payload on the app's state
      const { payloads } = _this.props
      payloads.map((val, i) => {
        if (val._id === payload._id) {
          val.sharedWith = newArr
        }
      })
      _this.props.setPayloads(payloads)
      _this.props.handleSharedEmailUsers(payloads)
    } catch (error) {
      throw error
    }
  }

  // Store the shared payloads permissions
  async updateSharedPermissions() {
    try {
      const { selectedPayload } = _this.state
      const { sharedPermissions, _id } = selectedPayload
      if (!sharedPermissions) throw new Error("Error sharing project")

      // Permissions data
      const user = getUser()
      const shareLevel = 20
      const shareLevelText = "view-only-time-based"
      const _shareEndDate = new Date()
      // Stablish the date from the next 30 days
      _shareEndDate.setDate(_shareEndDate.getDate() + 30)
      // Converts date to timestamp
      const shareEndDate = Math.floor(_shareEndDate.getTime() / 1000)

      const permission = {
        email: user.email,
        userId: user._id,
        shareLevel,
        shareLevelText,
        shareEndDate,
      }
      // Gets the last registered entry
      // of the current user
      const lastEntryIndex = sharedPermissions.findIndex(
        val => val.userId === user._id
      )
      // Deletes the last entry if exists
      if (lastEntryIndex >= 0) {
        sharedPermissions.splice(lastEntryIndex, 1)
      }
      // Adds the new entry to the array
      sharedPermissions.push(permission)

      // Updates the payload
      const metadata = {
        _id: _id,
        sharedPermissions,
      }
      const updateResult = await updatePayload(metadata)
      if (!updateResult) throw new Error("Error sharing project")

      // Finds the payload index associated
      const payloads = _this.props.payloads
      const associatedPayloadIndex = payloads.findIndex(
        val => val._id === selectedPayload._id
      )
      // Update local payload
      payloads[associatedPayloadIndex] = updateResult.metadata

      // Deprecated -> https://trello.com/c/t45jWKLA
      // Updates the payload metadata in the client state ( locally )
      // payloads[associatedPayloadIndex].meta.metadata = updateResult.metadata

      // Updates the payload metadata on the server
      // const payloadUpdate = {
      //   _id: payloads[associatedPayloadIndex]._id,
      //   meta: payloads[associatedPayloadIndex].meta,
      // }
      // const payloadUpdateResult = await updatePayload(payloadUpdate)
      // if (!payloadUpdateResult) throw new Error("Error sharing project")

      // Updates the payloads array of the app
      _this.props.setPayloads(payloads)
    } catch (error) {
      throw error
    }
  }

  async logFileAccess(type) {
    try {
      const { _id, fileAccess } = _this.state.selectedPayload
      const newAccess = {
        timestamp: Math.floor(new Date().getTime() / 1000),
        userId: getUser()._id,
        action: type,
      }
      fileAccess.push(newAccess)

      const metadata = {
        _id: _id,
        fileAccess: fileAccess,
      }
      const updateResult = await updatePayload(metadata)
      // Finds the payload index associated
      const payloads = _this.props.payloads
      const associatedPayloadIndex = payloads.findIndex(
        val => val._id === selectedPayload._id
      )
      // Update local payload
      payloads[associatedPayloadIndex] = updateResult.metadata
    } catch (error) {
      console.log(error)
    }
  }
  // Update shared projects
  async updateSharedP(emails) {
    try {
      const sharedWith = []
      const msgValidator = "could not find user in database"

      const payloadId = _this.state.selectedPayload._id

      const shadowUsers = await getShadowUsers()
      const shadowUsersArray = shadowUsers.shadowUsers

      for (let i = 0; i < emails.length; i++) {
        const val = emails[i]
        const result = await updateSharedProjects(val, payloadId)
        // Adds the data to the sharedWith array
        if (result.success) {
          const sharedData = {
            email: val,
            id: result.userId,
            isShadowUser: false,
          }
          sharedWith.push(sharedData)
        }
        if (!result.success && result.message === msgValidator) {
          //Validates if a shadowUser is already
          //registered with the shared email
          const shaowUserExist = shadowUsersArray.find(
            value => value.email == val
          )
          if (!shaowUserExist) {
            await newShadowUser({ email: val })
          }

          const sharedResult = await shadowUsersUpdateShared(val, payloadId)

          // Adds the data to the sharedWith array

          const sharedData = {
            email: val,
            id: sharedResult.shadowUserId,
            isShadowUser: true,
          }
          sharedWith.push(sharedData)

          //console.log("sharedResult", sharedResult)
        }
      }
      return sharedWith
    } catch (error) {
      throw error
    }
  }
  newVersion() {
    _this.props.addNewVersion(_this.props.selectedPayload)
  }

  startChat() {
    _this.props.showModal("chat")
  }

  changeIcon() {
    _this.props.hideCurrentModal()
    _this.props.showModal("change payload icon")

  }

}

PayloadModal.propTypes = {
  addNewVersion: PropTypes.func,
  changePayloadIcon: PropTypes.func,
}
