import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Box, Button } from 'adminlte-2-react'
import Jdenticon from 'react-jdenticon'

let _this

class TokenCard extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      token: props.token
    }

  }

  render() {
    const { token, currentView } = _this.props
    const icon = token.icon

    return (
      <>
        <Box className='hover-shadow mt-2' id='token-card'>
          <Row className='text-center'>
            <Col sm={12} className='text-center mt-2 '>
              {icon ? <div className="">
                <img
                  // temporarily set to use the default
                  // icons until the thumbnails created in this task -->
                  // https://trello.com/c/Bo2Udccy can be retrieved
                  src={icon} /* src={val.meta.filePreview} */
                  className="payload-thumbnail"
                  alt="thumbnail"
                />
              </div>

                : <Jdenticon size='100' value={token.tokenId} />}
              <hr />
            </Col>
            <Col sm={12} className='flex justify-content-center  mb-3'>
              <div className='info-container'>
                {currentView === 'my tokens' && <p className='info-content'>
                  <b>Ticker: </b>
                  <span> {token.ticker}</span>
                </p>}
                {currentView === 'project tokens' && <p className='info-content'>
                  <b>Payload: </b>
                  <button className='btn-a-style' onClick={() => _this.goToAssociatedPayload(token.tokenId)}> {token.ticker}</button>
                </p>}

                <p className='info-content'>
                  <b>Balance</b>
                  <span>{token.qty}</span>
                </p>
              </div>
            </Col>

            {
              currentView === 'project tokens' &&
              <Row>
                <Col xs={4}>
                  <Button className='btn btn-primary ml-1 mr-1 token-btn' text='Export' onClick={() => _this.openExportModal(token)} />

                </Col>
                <Col xs={4}>
                  <Button className='ml-1 mr-1 btn btn-primary token-btn' text='Burn' onClick={() => _this.openBurnModal(token)} />

                </Col>
                <Col xs={4}>
                  <Button className='ml-1 mr-1 token-btn' text='Info' onClick={() => _this.openInfo(token)} />

                </Col>
              </Row>
            }

            {
              currentView === 'my tokens' &&
              <Row className='token-btn-wrapper'>
                <Col xs={6}>
                  <Button className='btn btn-primary ml-1 mr-1 token-btn' text='Export' onClick={() => _this.openExportModal(token)} />

                </Col>
                <Col xs={6}>
                  <Button className='btn btn-primary ml-1 mr-1 token-btn' text='Redeem' onClick={() => _this.openRedeemModal(token)} />

                </Col>
                <Col xs={6}>
                  <Button className='ml-1 mr-1 btn btn-primary token-btn' text='Burn' onClick={() => _this.openBurnModal(token)} />

                </Col>
                <Col xs={6}>
                  <Button className='ml-1 mr-1 token-btn' text='Info' onClick={() => _this.openInfo(token)} />

                </Col>
              </Row>
            }

          </Row>
        </Box>
      </>
    )
  }

  shouldComponentUpdate() {
    return false
  }

  round(value, decimals) {
    return Number(Math.round(value + `e${decimals}`) + `e-${decimals}`)
  }

  openExportModal(token) {
    _this.props.setSelectedToken(token)
    _this.props.showModal("export token")
  }
  openRedeemModal(token) {
    _this.props.setSelectedToken(token)
    _this.props.showModal("redeem token")
  }
  goToAssociatedPayload(tokenId) {
    if (!tokenId) return
    _this.props.navigateToProject(tokenId)
  }
  openInfo(token) {
    const url = `https://token.fullstack.cash/?tokenid=${token.tokenId}`
    window.open(url, '_blank')
  }
  openBurnModal(token) {
    _this.props.setSelectedToken(token)
    _this.props.showModal("burn token")
  }
}
TokenCard.propTypes = {
  token: PropTypes.object.isRequired,
}
export default TokenCard