/* eslint-disable */
/**
 * This modal renders the form
 * to share payloads
 */
import React from "react"
import PropTypes from "prop-types"
import { Button, Inputs, Box, Content } from "adminlte-2-react"
import { NotificationManager } from "react-notifications"
import { contact } from "../../../services/contact"

let _this

export class ShareMethod extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      bodyMsg: "Select Sharing Method",
      inFetch: false,
    }
  }

  render() {
    const { bodyMsg } = _this.state
    return (
      <Content
        className="disclosure-form-container "
        modal
        modalCloseButton={true}
        onHide={_this.props.onHide}
        show={true}
        modalSize="lg"
        /* Modal Header */
        title={"Share Document With Others"}
        modalFooter={
          <React.Fragment>
            <Box className="tx-footer-box" loaded={!_this.state.inFetch}>
              <div className="tx-footer">
                <Button
                  type="primary"
                  text="Close"
                  onClick={_this.props.onHide}
                />

                <div>
                  <Button
                    type="primary"
                    text="Share to Email "
                    onClick={_this.shareToEmail}
                  />
                  <Button
                    type="primary"
                    text="Copy Share Link to Clipboard "
                    onClick={_this.copyToClipboard}
                  />
                </div>
              </div>
            </Box>
          </React.Fragment>
        }
      >
        <h3 className="text-green text-center">{bodyMsg}</h3>
      </Content>
    )
  }
  componentDidMount() {}

  // Adds the query params link to the clipboard
  // to access to a shared payload directly
  // after login
  copyToClipboard() {
    try {
      // Obtains the page domain
      const host = window.origin
      // Payload ID to share
      const { _id } = _this.props.selectedPayload

      const url = `${host}/login?shareId=${_id}`
      const textArea = document.createElement("textarea")
      textArea.value = url // copyText.textContent;
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand("Copy")
      textArea.remove()
      _this.setState({
        bodyMsg: "Document Copied To Clipboard!",
      })
    } catch (error) {
      console.error(error)
    }
  }
  async shareToEmail() {
    try {
      _this.setState({
        inFetch: true,
      })

      const data = _this.props.dataToShareByEmail
      // Fetch contact endpoint
      const result = await contact(data)

      if (!result) {
        throw new Error("Error")
      }
      _this.setState({
        bodyMsg: "Document Shared By Email",
        inFetch: false,
      })
      NotificationManager.success("Document Shared By Email!", "Success", 2000)
    } catch (error) {
      NotificationManager.error(error.message, "Sharing By Email Error")
      _this.setState({
        inFetch: false,
      })
    }
  }
}

ShareMethod.propTypes = {
  dataToShareByEmail: PropTypes.object,
  selectedPayload: PropTypes.object,
  onHide: PropTypes.func,
}
