/* eslint-disable */
/**
 *
 * This component renders the projects view and the
 * 'sharedWithMe' view and also other related modals
 *
 */
import React from "react"
import { PayloadCards } from "./payload-cards"
import { SharedWithMe } from "./shared-with-me"
import { MyTokens } from "./my-tokens"
import { Downloads } from "./downloads"
import { ProjectTokens } from "./project-tokens"
import { Help } from "./help"


import { NewPayload } from "../projects/modals/new-payload"
import { PayloadModal } from "../projects/modals/payload"
import { SharedPayloadModal } from "../shared-payloads/modals/payload"
import { getPayloadIcon } from "../../services/metadatas"
import { getPayloadByIdBULK } from "../../services/payload"
import { getUser } from "../../services/auth"
import { getUserById } from "../../services/users"
import { Chat } from "../shared-payloads/modals/chat"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layout"
import PropTypes from "prop-types"

import {
  Content,
  Row,
  Button,
  Col,
  ButtonGroup,
  Inputs,
} from "adminlte-2-react"

const { Text, Select } = Inputs

// eslint-disable-next-line
const simpleOptions =
  "simpleOptions = ['Alabama', 'Alaska', 'California', 'Delaware', 'Tennessee', 'Texas', 'Washington']"
let _this
export class Views extends React.Component {
  constructor(props) {
    super(props)
    _this = this
    this.state = {
      showModal: false,
      payloads: [],
      project: {},
      displayTx: false,
      showArchived: false,
      sharedPayloads: [],
      inFetch: false,
      previousVersion: {},
      displayChat: false,
      bulkSharedEmails: [],
      showTabToggleBtn: false,
      expandTabs: false,
    }
  }

  render() {

    const { currentView } = _this.props
    return (
      <Layout inFetch={_this.state.inFetch}>
        <Content className="disclosure-main-container">
          <Row>
            <Col
              xs={12}
              className={`disclosure-buttons ${_this.state.expandTabs ? "disclosure-buttons-expanded" : ""
                }`}
            >
              { /** Views Header */}
              <ButtonGroup>
                {currentView === "projects" && (
                  <>
                    <Button
                      className="active-Option"
                      key="Create"
                      id="create"
                      text="All"
                    />
                    <Button
                      key="add"
                      className="btn-icon-add"
                      icon="fasplus"
                      onClick={() => _this.props.showModal("new payload")}
                    />
                  </>
                )}
                {currentView !== "projects" && (
                  <Button
                    className="active-Option"
                    key="Shared"
                    id="shared"
                    text="All"
                  />
                )}
                {currentView === "my tokens" && (
                  <Button
                    key="Get Deposit Address"
                    id="Get Deposit Address"
                    text="Get Deposit Address"
                    onClick={() => _this.props.showModal("deposit address")}

                  />
                )}

                {/** Button to show / hide the emails */}
                {_this.state.showTabToggleBtn && (
                  <div
                    className="tab-btn-more"
                    onClick={() => {
                      _this.setState({ expandTabs: !_this.state.expandTabs })
                    }}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={_this.state.expandTabs ? "sort-up" : "sort-down"}
                    />
                  </div>
                )}

                {_this.state.bulkSharedEmails.map((val, i) => {
                  return (
                    <Button key={`${val}-${i}`} id="tab-email" text={val} />
                  )
                })}
              </ButtonGroup>
            </Col>
            { /** Views Payloads Header */}

            {(currentView === "shared with me" || currentView === "projects") && <Col xs={12}>
              <div className="control-input">
                <Text
                  placeholder="Search results"
                  labelPosition="none"
                  iconLeft="fas-search"
                  disabled={true}
                />
                <Select
                  disabled={true}
                  labelPosition="none"
                  options={[
                    { text: "Sort By Date", value: "1" },
                    { text: "Sort By Username", value: "2" },
                    { text: "Sort By Company", value: "3" },
                  ]}
                />
                <div className="form-group">
                  <div className="archived-filter">
                    <span> Show Archived</span>
                    <input
                      className="custom-checkbox"
                      aria-label="Show Archived Checkbox"
                      type="checkbox"
                      id="archived"
                      name="archived"
                      onChange={_this.switchToArchive}
                    />
                  </div>
                </div>
              </div>
            </Col>}
            { /** Views Body */}

            {
              <Col xs={12} className="disclosure-container">
                {currentView === "projects" && (
                  <PayloadCards
                    {..._this.props}
                    getPayloadInfo={_this.getPayloadInfo}
                    showArchived={_this.state.showArchived}
                    payloadList={_this.props.payloads}
                  />
                )}
                {currentView === "shared with me" && (
                  <SharedWithMe
                    {..._this.props}
                    getPayloadInfo={_this.getPayloadInfo}
                    showArchived={_this.state.showArchived}
                  />
                )}
                {currentView === "my tokens" && (
                  <MyTokens  {..._this.props} />
                )}
                {currentView === "downloads" && (
                  <Downloads  {..._this.props} />
                )}
                {currentView === "help" && (
                  <Help  {..._this.props} />
                )}
                {currentView === "project tokens" && (
                  <ProjectTokens
                    getProjects={_this.props.getProjects}
                    navigateToProject={_this.props.navigateToProject} {..._this.props} />
                )}
              </Col>
            }

            { /** Views Modals */}

            <NewPayload
              {..._this.props}
              previousVersion={_this.state.previousVersion}
            />

            {currentView === "projects" && (
              <PayloadModal
                {..._this.props}
                addNewVersion={_this.addNewVersion}
                handleSharedEmailUsers={_this.getAllSharedEmailUsers}
              />
            )}
            {currentView === "shared with me" && (
              <SharedPayloadModal
                {..._this.props}
                addNewVersion={_this.addNewVersion}
              />
            )}

            <Chat {..._this.props} />
          </Row>
        </Content>
      </Layout>
    )
  }
  addNewVersion(payload) {
    _this.props.showModal("new payload")
    _this.setState({
      previousVersion: payload,
    })
  }
  switchToArchive() {
    _this.setState({
      showArchived: !_this.state.showArchived,
    })
  }
  async getPayloadInfo(payload) {
    // Fetch metadata associated to payload selected
    // Deprecated -> https://trello.com/c/t45jWKLA
    // const metadataResult = await getmetadataById(payload.meta.metadata)
    // const metadata = payload.metadata
    const { currentView } = _this.props
    _this.setState({
      // metadata,
      displayTx: true,
    })
    _this.props.setSelectedPayload(payload)
    if (currentView === "projects") {
      _this.props.showModal("payload details")
    }
    if (currentView === "shared with me") {
      _this.props.showModal("shared payload details")
    }
  }

  async componentDidMount() {
    const { path } = _this.props
    // change to selected view
    if (path === "/shared/") {
      _this.props.changeViewTo("shared with me")
      const sharedPayloads = await _this.getSharedPayloads()
      _this.validateSharedAccess(sharedPayloads)
      _this.getAllSharedEmailOwners(sharedPayloads)
    } else if (path === "/tokens/") {
      _this.props.changeViewTo("my tokens")
    } else if (path === "/downloads/") {
      _this.props.changeViewTo("downloads")
    } else if (path === "/help/") {
      _this.props.changeViewTo("help")
    } else {
      _this.props.changeViewTo("projects")
      const buttonCreate = document.getElementById("create")
      buttonCreate && buttonCreate.focus()
    }
  }
  componentDidUpdate() {
    if (_this.props.project !== _this.state.project) {
      _this.setState({
        project: _this.props.project,
        expandTabs: false,
      })
    }
    if (_this.props.payloads !== _this.state.payloads) {
      const { path } = _this.props
      const payloads = _this.props.payloads

      _this.setState({
        payloads,
      })
      // Only show the tabs in the projects view
      if (path === "/project/" || path === "/") {
        _this.getAllSharedEmailUsers(payloads)
      } else {
        _this.setState({
          bulkSharedEmails: [],
        })
      }
    }
  }
  toggleModalForm() {
    _this.setState(prevState => ({
      ...prevState,
      showModal: !_this.state.showModal,
      previousVersion: {},
    }))
  }
  toggleModalTx() {
    _this.setState(prevState => ({
      ...prevState,
      displayTx: !_this.state.displayTx,
    }))
  }
  async payloadICons(payloadId) {
    try {
      if (!payloadId) return
      const result = await getPayloadIcon(payloadId)
      return result
    } catch (error) {
      console.error(error)
    }
  }
  async getSharedPayloads() {
    _this.setState(prevState => ({
      ...prevState,
      inFetch: true,
    }))
    const userId = getUser()._id
    const user = await getUserById(userId)
    const sharedPayloadsId = user.user.sharedPayloads

    let shared = []

    let offset = 0
    const limit = 20

    while (offset < sharedPayloadsId.length) {
      // console.log("offset", offset)
      // console.log("limit", limit)

      const payloadsToFetch = sharedPayloadsId.slice(offset, offset + limit)

      const payload = await getPayloadByIdBULK(payloadsToFetch)
      // debugger
      const metadatas = payload.metadatas
      const _metadatas = []

      for (let i = 0; i < metadatas.length; i++) {
        const metadata = metadatas[i]
        if (metadata.meta.filePreview) {
          const url = await _this.payloadICons(metadata._id)
          metadata.meta.thumbnailIpfsUrl = url
        }
        _metadatas.push(metadata)

      }
      shared = shared.concat(_metadatas)

      _this.setState({
        sharedPayloads: shared,
      })
      _this.props.setSharedWithMe(shared)

      setTimeout(() => {
        _this.setState({
          inFetch: false,
        })
      }, 700)

      offset = offset + limit
      //debugger
    }

    _this.props.setSharedWithMe(shared)
    _this.setState({
      sharedPayloads: shared,
      inFetch: false,
    })
    // debugger
    return shared
  }
  closeChat() {
    _this.setState({
      displayChat: false,
      section: "shared",
    })
    setTimeout(() => {
      _this.toggleModalTx()
    }, 200)
  }

  // validates that the user get access to the payload provided by url parameter
  validateSharedAccess(payloads) {
    try {
      let hasAccess = false
      const { queryPayloadId } = _this.props
      if (!queryPayloadId) {
        return
      }
      /**
       * TODO: Validate access
       */

      _this.openSharedPayload(payloads, queryPayloadId)

      return hasAccess
    } catch (error) {
      return false
    }
  }
  async openSharedPayload(sharedPayloads, payloadId) {
    try {
      let selectedPayload
      for (let i = 0; i < sharedPayloads.length; i++) {
        if (payloadId === sharedPayloads[i]._id) {
          selectedPayload = sharedPayloads[i]
        }
      }
      if (selectedPayload) {
        await _this.getPayloadInfo(selectedPayload)
      }
      _this.props.onQueryFound()
    } catch (error) { }
  }

  // Gathers the email list of
  // which users have been shared
  // payloads associated to the selected project
  getAllSharedEmailUsers(payloads) {
    try {
      if (!payloads.length) {
        _this.setState({
          bulkSharedEmails: [],
          showTabToggleBtn: false,
        })
        return
      }
      let _bulkSharedObj = []
      let _bulkSharedEmails = []
      payloads.map(payload => {
        if (payload.sharedWith) {
          _bulkSharedObj = _bulkSharedObj.concat(payload.sharedWith)
        }
      })
      _bulkSharedObj.map(val => {
        if (val.email) {
          _bulkSharedEmails.push(val.email)
        }
      })

      // Mock data , for test
      // _bulkSharedEmails = []
      // for (let i = 0; i < 25; i++) {
      //   const randomNumber = Math.floor(Math.random() * (1000000 - 100)) + 100
      //   const mockEmail = `${randomNumber}@email.com`
      //   _bulkSharedEmails.push(mockEmail)
      // }

      _this.setState({
        bulkSharedEmails: _bulkSharedEmails,
      })

      _this.toggleTab()
    } catch (error) { }
  }

  // Verifies if is necessary to display the show / hide
  // button to show the tab email list
  toggleTab() {
    try {
      // Ignores the verification if the email tab
      // is expanded
      if (_this.state.expandTabs) {
        return
      }
      const elementWrapper = document.getElementsByClassName(
        "disclosure-buttons"
      )
      const element = elementWrapper[0]
      const isOverflown =
        element.scrollHeight > element.clientHeight + 5 ||
        element.scrollWidth > element.clientWidth + 5

      _this.setState({
        showTabToggleBtn: isOverflown,
      })
    } catch (error) { }
  }
  // Gets the email list of
  // users that have shared payloads
  // with the current logged user
  getAllSharedEmailOwners(payloads) {
    try {
      if (!payloads.length) {
        _this.setState({
          bulkSharedEmails: [],
          showTabToggleBtn: false,
        })
        return
      }
      let _bulkSharedEmails = []
      payloads.map(payload => {
        const { emailOwner } = payload.meta
        if (emailOwner) _bulkSharedEmails = _bulkSharedEmails.concat(emailOwner)
      })

      // Mock data , for test
      // _bulkSharedEmails = []
      // for (let i = 0; i < 25; i++) {
      //   const randomNumber = Math.floor(Math.random() * (1000000 - 100)) + 100
      //   const mockEmail = `${randomNumber}@email.com`
      //   _bulkSharedEmails.push(mockEmail)
      // }

      // Delete repeats
      const newArr = [...new Set(_bulkSharedEmails)]
      _this.setState({
        bulkSharedEmails: newArr,
      })

      _this.toggleTab()
    } catch (error) { }
  }

  addNewVersion() {
    _this.props.showModal("new payload")
  }
}

Views.propTypes = {
  project: PropTypes.object,
  queryPayloadId: PropTypes.string,
  onQueryFound: PropTypes.func,
}
